#warnUser {
  width: 100vw;
  max-width: 1200px;
  max-height: calc(100vh - 38px);
  overflow: auto;

  input[type="number"] {
    width: 80px;
  }

  .else-timer {
    margin-top: 1px;
  }

  textarea {
    height: 250px;
  }

  .column, .row {
    flex-shrink: 0;
  }

  .restriction-duration{
    display: flex;
    align-items: flex-end;
    padding: 8px;
    margin-top: 8px;
    border-top: 1px solid $color-grey-light;
    .custom-date-input{
      width: 150px;
    }
  }

  .punishment-section{
    align-items: flex-start;
  }
}

@media screen and (max-width: $screen-size-medium) {
  #warnUser {
    .justify-end {
      justify-content: center;
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #warnUser {
    padding: 8px !important;
  }
}