#changeTitle {
  display: flex;
  flex-direction: column;
  width: 100%;
  //max-width: 500px;
  .modal-topline{
    font-size: 15px;
    line-height: 1.2;
  }
  //.text {
  //  display: flex;
  //  flex-direction: row;
  //  white-space: nowrap;
  //  .title {
  //    @include overflow-ellipsis();
  //
  //    .username {
  //      font-size: 15px;
  //      font-weight: bold;
  //      color: $color-text-medium-2;
  //      margin-bottom: 4px;
  //
  //      &::after {
  //        content: ",";
  //        display: inline;
  //      }
  //
  //      &:last-child {
  //        &::after {
  //          content: "";
  //          display: none;
  //        }
  //      }
  //    }
  //  }
  //}
  .custom-text-input{
    width: 100%;
  }
}
