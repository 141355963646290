.register {
  display: flex;
  flex-direction: column;
  width: 30%;
  color: #FFFFFF;

  .title {
    font-size: 37px;
    font-weight: bold;
  }

  .text {
    font-size: 20px;
  }

  .btn {
    text-align: center;
  }
}
