.edit-status {
  width: 500px;
  max-height: 100vh;
  overflow: auto;

  .publish-status {
    .message-container {
      flex-direction: column;

      .message-input-wrapper {
        width: 100%;

        .message-input {
          textarea {
            height: 150px !important;
          }
        }
      }

      .editstatus-btn-group {
        align-self: flex-end;

        .btn {
          margin-top: 8px;
          margin-right: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .edit-status {
    width: 100%;

    .publish-status {
      height: 100%;
      max-width: 100vw;
      max-height: unset;
      box-shadow: none;
    }
  }
}
