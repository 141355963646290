#articles {
  .pane {
    margin: 0;
  }
}

.articles-grid {
  display: flex;
  flex-wrap: wrap;

  .row-grid {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
}

.card-article-small {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #FFF;
  padding: 0px !important;
  margin: 16px;
  min-width: 240px;
  width: calc((100% / 3) - 32px);
  overflow: hidden;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius;

  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
  }

  .card-information{
    position: absolute;
    z-index: 1;
    left: -40px;
    top: -40px;
    width: 80px;
    height: 80px;
    border-radius: $border-radius-rounded;
    background-color: $color-red;
    i{
      position: absolute;
      bottom: 18px;
      right: 18px;
      z-index: 2;
      fill: #FFF;
    }
  }

  .img-link-articles {
    display: flex;
    justify-content: center;
    width: 100%;

    .thumb {
      width: 100%;
      height: auto;
      height: 100%;
      width: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    flex: 1;


    .topline {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      //overflow: hidden;
      margin: 16px 0;
      padding-top: 0;

      .title-link-articles, .title-link-articles:focus, .title-topic {
        display: flex;
        align-items: center;
        color: $color-text-default;
        font-weight: 700;
        text-decoration: none;
        font-size: 20px;
        line-height: 25px;
        width: 100%;
      }

      .text {
        /*
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 8px;
        right: 8px;
        */
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      background-color: #FFF;

      
      .article-img {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 200px;
        height: 200px;
        overflow: hidden;
        border-radius: $border-radius $border-radius 0 0;
        background-color: $color-grey-light;
        position: relative;

        .title-link-articles {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          text-decoration: none;

          span {
            position: absolute;
            bottom: 8px;
            right: 8px;
            font-size: 10px;
            user-select: none;
          }
        }

        img {
          position: absolute;
          min-width: 100%;
          min-height: 100%;
          width: auto;
          //max-width: 100%;
          //max-height: 100%;
          user-select: none;

          &.no-illustration {
            min-width: unset;
            min-height: unset;
          }
        }
      }

      .excerpt {
        font-size: 15px;
        line-height: 18px;
        margin: 0px 0 16px 0;
        padding: 0 16px;
        color: $color-text-medium-2;
        font-family: $font-for-readability;
        @extend .break-long-text;

        iframe, object, a, .bbcode_attachment, img {
          display: none !important;
        }

        &.quote {
          quotes: "“" "”";
          &::before {
            color: rgba($color-main, .4);
            content: open-quote;
            font-size: 4em;
            margin-right: 0.25em;
            vertical-align: -0.4em;
            font-family: Sans-Serif;
          }

          &::after {
            font-size: 4em;
            margin-left: 0.25em;
            vertical-align: -0.6em;
            color: rgba($color-main, .4);
            content: close-quote;
            font-family: Sans-Serif;
          }
        }
      }

      .tag-list {
        padding: 0 16px;
        margin: 8px 0;
      }

      .user {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        padding: 0 16px;

        .avatar {
          height: 36px;
          width: 36px;
        }

        .infos {
          display: flex;
          flex-direction: column;
          width: 100%;
          font-size: 14px;
          max-width: calc(100% - 36px);
          padding-right: 8px;

          .username-time {
            color: $color-text-grey-medium;
            margin-bottom: 4px;

            a {
              text-decoration: none;
              color: $color-primary;
            }
          }

          span {
            color: $color-text-grey-medium;
          }

          .user-blog, .forum-name {
            color: $color-text-medium-2;
            margin-top: -2px;
            a{
              text-decoration: none;
              color: inherit !important;
              font-weight: 500;
              font-size: 12px;
            }
          }




        }
      }
    }
    .date-since {
      font-weight: 300;
      font-size: 12px;
      margin-top: 10px;
      display: block;
    }    
    .blog-name, .title-forum {
      color: $color-text-medium-2;
      text-decoration: none;
      line-height: 18px;
      font-weight: 500;
    }

    .footer {
      display: flex;
      flex-direction: row;
      border-top: 1px solid $color-grey-light;
      padding: 8px 16px;
      justify-content: space-between;

      .likes, .subjects, .answers-count, .views {
        position: relative;
        top: unset;
        right: unset;
        color: $color-text-medium-2;
        fill: $color-text-medium-2;
        padding: 4px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        background-color: transparent;

        description
        i {
          margin-right: 8px;
        }
      }

      .answers-count {
        i {
          width: 12px;
          margin-right: 8px;
        }
      }

      .user {
        display: flex;
        align-items: center;

        .user-thumb {
          height: 36px;
          width: 36px;
          border-radius: 500px;
        }

        .infos {
          display: flex;
          margin-left: 8px;
          width: 100%;
          font-size: 12px;

          .username-time {
            color: $color-text-grey-medium;

            a {
              text-decoration: none;
              color: $color-primary;
            }
          }

          span {
            color: $color-text-grey-medium;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .card-article-small {
    width: calc(100% / 2 - 32px);

    .wrapper {
      .topline {
        overflow: visible;
        flex-wrap: wrap;

        .story-thumb {
          order: 2;
        }

        .title-link-articles {
          order: 3;
          height: unset;
        }

        .date-since {
          order: 1;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .card-article-small {
    min-width: unset;
    flex-grow: 1;
    width: calc(100% - 16px);
    margin: 16px 0;

    .wrapper {
      .topline {
        .title-link-articles, .title-topic {
          height: unset;
        }
      }
    }
  }
}
