.chat-editor-container{
  display: flex;
  align-items: center;
  flex-grow: 1;

  .emojiSelect{
    display: flex;
    align-items: flex-end;
    //.emojiSelectButton{
    //  height: 36px;
    //}
  }
  .add-comment{
    overflow: hidden;
  }
  .mk-editor-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: #fff;
    padding: 0;
    margin: 0;
    cursor: pointer;
    line-height: 1.2em;
    height: 2em;
    width: 2em;
    margin: 0;
    border-radius: 100%;
    i{
      width: 16px;
    }
  }
  .mk-editor{
    width: 100%;
    transition: all .2s linear;
    margin-left: auto;
    &.active{
      width: 150px;
      transition: all .2s linear;
    }
  }
}