@import 'src/sass/_variables.scss';

.content-locked{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .content-locked-head{
        width: 100%;
        overflow: hidden;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: red;
        img{
            margin-top: -10px;
            // background: red;
        }
    }
    .content-locked-body{
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .content-locked-title{
            font-size: 1.35em;
            font-weight: 600;
            line-height: 1.3em;
            margin-bottom: 20px;
            text-align: center;
            span{
                color : $v3-color-primary;
            }
        }   
        .content-locked-description{
            margin-bottom: 28px;
            line-height: 1.3em;
            font-weight: 500;
            font-size: .8em;
            text-align: center;
        }
        .content-locked-action{
            & > *{
                margin-bottom: 8px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &-card{
        background: $v3-color-bg-light-grey;
        border-radius: $v3-default-border-radius * 2;
        overflow: hidden;
    }
    &-banner{
        flex-direction: row;
        min-height: 160px;
        .content-locked-head{
            margin-right: 32px;
            width: unset;
            // height: 100%;
            height: unset;
            width: 250px;

            img{
                margin-top: 0px;
                margin-left: -10px;
            }
        }
        .content-locked-body{
            padding: 0 16px;
            justify-content: space-evenly;
            flex-grow: 1;
            flex-shrink: 0;
            align-items: flex-start;
            .content-locked-title, .content-locked-description{
                text-align: left;
                margin: 0;
            }
            .content-locked-description{
                font-size: .9em;
            }
            .content-locked-action{
                & > *{
                    margin-bottom: 0;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}