#alert-modal{
  background: #FFFFFF;
  padding: 16px;
  border-radius: 12px;
  .alert-modal-title{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 4px;
  }
  .alert-modal-footer{
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
}