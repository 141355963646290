@import 'src/sass/_variables.scss';
.drawer {
    position: fixed;
    z-index: 1001;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: fadeIn 0.3s;

    .drawer-close{
        background: rgba(23, 23, 23, 0.55);
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    .drawer-wrapper{
        position: absolute;
        bottom: calc(0px + env(safe-area-inset-bottom));
        left: 0;
        right: 0;
        z-index: 2;
        max-height: 85%;
        width: 100%;
        border-radius: 16px;
        background-color: #ffffff;
        animation: slideIn 0.3s;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .drawer-close-btn{
            position: absolute;
            top: 10px;
            right: 10px;
            height: 26px;
            width: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            // background: $v3-color-bg-light-grey;
            background: #FFF;
            border-radius: 50px;
            font-weight: 500;
            font-size: .8rem;
            box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.12), 0 0 2px 2px rgba(0, 0, 0, 0.05);
            // box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            .svg-inline--fa{
                color: #000;
            }
            &:hover{
                background: $v3-color-bg-light-grey-hover;
            }
        }
        .drawer-head{
            &.has-head-contrent{
                padding: 16px;
                user-select: none;
                text-align: center;
                // text-transform: capitalize;
                color: #000;
                font-size: .85em;
                font-weight: 500;
                letter-spacing: -.015em;
            }
        }
        .drawer-content{
            flex: 1;
            overflow-y: auto;
            // flex-shrink: 0;
            color: #000;
            display: flex;
        }
    }
    &.modal-mode{
       display: flex;
       justify-content: center;
       align-items: center; 
       &.large{
            >.drawer-wrapper{
                max-width: 800px;
            }
        }
        &.xlarge{
            >.drawer-wrapper{
                max-width: $screen-size-desktop;
                // max-height: 90vh;
            }
        }
        &.small{
            >.drawer-wrapper{
                max-width: 400px;
                // max-height: 540px;
            }
        }
        &.xsmall{
            >.drawer-wrapper{
                max-width: 280px;
                // max-height: 380px;
            }
        }
        .drawer-wrapper{
            position: relative;
            max-width: 580px;
            // max-height: 640px;
            // max-height: 70vh;

            box-shadow:
        0 1px 1px hsl(0deg 0% 0% / 0.075),
        0 2px 2px hsl(0deg 0% 0% / 0.075),
        0 4px 4px hsl(0deg 0% 0% / 0.075),
        0 8px 8px hsl(0deg 0% 0% / 0.075),
        0 16px 16px hsl(0deg 0% 0% / 0.075)
        ;
        }
    }
}



@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes slideIn {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}