.forums-topic {
  position: relative;

  pre {
    white-space: -moz-pre-wrap;
    /* Mozilla, supported since 1999 */
    white-space: -pre-wrap;
    /* Opera */
    white-space: -o-pre-wrap;
    /* Opera */
    white-space: pre-wrap;
    /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
    word-wrap: break-word;
  }

  .forums-topic-multiCitation-popup {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 32px;
    bottom: 75px;
    background-color: rgba($color-primary, .9);
    padding: 16px;
    border-radius: $border-radius;
    box-shadow: $box-shadow-2;
    color: #FFF;
    z-index: 4;

    .btn-rounded {
      background-color: #FFF;
      color: $color-text-default;
      margin-right: 16px;
      cursor: pointer;
    }

    .clean-multiple-quote {
      cursor: pointer;
      margin-top: 8px;
      font-size: 14px;
    }
  }

    .topic {
      color: $color-text-default;
      // padding: 16px 16px 0;
      display: flex;
      flex-direction: column;

      .wrapper-settings {
        .admin-select {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          color: $color-text-medium-2;
          text-align: center;

          .checkbox-action {
            background-color: $color-red;
            color: #FFF;
            margin-top: 8px;
          }
        }
      }

      .topic-header {
        display: flex;
        justify-content: space-between;
        
        .title {
          display: flex;
          margin: 16px 0 32px;
          //width: 80%;

          .avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
            margin-left: 8px;
            width: 75px;
            height: 75px;
          }

          .info-topic {
            display: flex;
            flex-wrap: wrap;
            width: 90%;

            .before-title {
              margin-bottom: 8px;
            }

            h1 {
              width: 100%;
              margin-bottom: 8px;
            }

            .topic-by {
              margin-bottom: 8px;
              color: $color-text-grey-medium;
              font-size: 14px;

              a {
                color: $color-primary;
                text-decoration: none;
              }
            }

            .date {
              margin-left: 4px;
              text-transform: lowercase;
              color: $color-text-grey-medium;
              font-size: 14px;
            }

            .tag-list {
              width: 100%;
            }
          }
        }
      }

      .first-message{
        margin: 24px 0;
        box-shadow: $box-shadow-light;
        border-radius: $border-radius;
        .first-message-label{
          display: block;
          font-size: 15px;
          margin-bottom: 8px;
          font-weight: bold;
          padding: 16px 8px;
          border-radius: $border-radius $border-radius 0 0;
          &.man {
            background-color: rgba($color-gender-man, .15);
          }

          &.woman {
            background-color: rgba($color-gender-woman, .15);
          }

          &.couple {
            background-color: rgba($color-gender-couple, .15);
          }

          &.couple-lesbian {
            background-color: rgba($color-gender-couple-lesbian, .15);
          }

          &.couple-gay {
            background-color: rgba($color-gender-couple-gay, .15);
          }

          &.trav {
            background-color: rgba($color-gender-trav, .15);
          }
        }
        .content{
          padding: 16px 8px;
          color: $color-text-default;
          font-size: .9em;
        // margin-bottom: 12px;
          line-height: 1.4em;
          white-space: pre-line;
          @extend .break-long-text;
        }
        .user-sign{
          width: 100%;
          border-top: 1px solid $color-grey-light;
          margin: 8px 8px 16px 8px;
          padding-top: 8px;
          word-break: break-word;
          line-height: 1;/*18px;*/
          font-size: 12px;
          font-family: $font-for-readability;
        }
      }

      .follow-topic {
        /*display: flex;
        width: 20%;
        justify-content: flex-start;
        align-items: flex-end;
        margin-right: 16px;
        margin-top: 16px;
        font-size: 12px;
        color: $color-text-grey-medium;
        font-weight: 700;*/
        align-items: flex-end;

        .icon-caret-up {
          fill: $color-main;
        }

        .dropdown {
          .dropdown-menu {
            width: 280px;
            max-height: 300px;
            position: relative;
          }

          span {
            background-color: #FFF;
            padding: 5px 8px;
            border-radius: $border-radius;
            box-shadow: $box-shadow-1;
          }

          .title-item {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 18px;
            background-color: $color-main;
            color: #fff;
            padding: 8px 16px;
          }

          .overflow-container {
            overflow: auto;
            height: 240px;

            .menu-item {
              a {
                display: flex;
                width: 100%;

                div {
                  font-size: 14px;

                  .avatar {
                    width: 35px;
                    height: 35px;
                    margin-right: 16px;
                  }
                }

                .user-infos {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;

                  .grade {
                    font-weight: 400;
                  }

                  div {
                    width: 100%;
                    font-size: 12px;
                  }
                }
              }

              a:hover + .user-infos a {
                color: $color-primary;
              }
            }
          }
        }
      }

      .answer-topic {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .wrapper-settings {
          padding: 0;
        }

        .answer-topic-admin-button {
          display: flex;
          margin-right: 16px;
          cursor: pointer;
          margin-left: auto;

        }
      }

      .poll-display {
        margin: 8px 0;

        .topline {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          color: #000;
          border: 0 solid transparent;
          border: 1px solid $color-grey-light;
          border-radius: $border-radius;

          .poll-number-of-vote {
            font-size: 14px;
            font-weight: normal;
            margin-left: 8px;
            color: $color-text-medium-2;
          }
        }

        .poll-block {
          margin: 8px 0;
          padding: 8px;

          .question {
            padding-bottom: 8px;
            font-size: 15px;
            font-weight: bold;
            border-bottom: 1px solid $color-grey-light;
          }

          .row {
            align-items: center;
            margin: 16px 0;

            input[type="radio"] {
              margin-right: 8px;
            }

            label {
              font-size: 15px;
              color: $color-text-default;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .column {
            margin: 16px 0;

            .choice-name {
              font-size: 15px;
              color: $color-text-default;
            }

            .choice-stat {
              font-size: 13px;
              color: $color-text-medium-2;
              margin-left: 16px;
            }

            .choice-stat-bar {
              border: 1px solid $color-primary;
              border-radius: 10px;
              height: 10px;
              width: 100%;
              margin-top: 4px;
              overflow: hidden;
              max-width: 500px;

              .inside-bar {
                background-color: $color-primary;
                transition: all linear .3s;
                height: 10px;
              }
            }
          }
        }

        .poll-baseline {
          justify-content: center;
          align-items: center;
          padding: 8px;

          .small {
            margin: 0 4px;
            cursor: pointer;
          }
        }
      }

      //h2 {
      //  margin: 16px 0;
      //  padding: 16px 0;
      //  font-size: 20px;
      //  color: $color-main;
      //}

      // FIRST POST OF TOPIC //
      .content-wrapper {
        display: flex;
        flex-wrap: wrap;
        background-color: rgba($color-grey-light, .2);
        margin-bottom: 16px;
        border-radius: $border-radius;

        .topline-user-answer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          width: 100%;
          font-weight: 400;
          padding: 16px;
          border-bottom: 1px solid $color-grey-light;

          a {
            text-decoration: none;
            color: $color-primary;
          }
        }

        .user-informations {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 16px 8px;
          width: 20%;
          font-size: 14px;

          div {
            color: $color-grey-darken;
          }

          .grade,
          .member-since,
          .role-status {
            margin-bottom: 16px;
          }

          .avatar {
            width: 70px;
            height: 70px;
            margin-bottom: 16px;
          }

          .message-count {
            color: $color-text-grey-medium;
            margin-bottom: 16px;
          }

          .symbols {
            display: inline-block;
            margin: 0 0 16px;
          }
        }

        .user-content {
          display: flex;
          flex-direction: column;
          padding: 16px 8px;
          align-items: flex-start;
          width: calc(80% - 16px);
          margin: 0 8px;
          word-break: break-all;

          img {
            max-width: 100%;
            max-height: 800px;
          }

          .title {
            padding-bottom: 8px;
            margin-bottom: 8px;
            font-weight: 700;
            font-size: 18px;
            width: 100%;
          }

          .user-text {
            width: 100%;
            padding-bottom: 32px;
            font-size: 15px;
            color: $color-text-default;
            line-height: 18px;
            word-break: break-word;

            a {
              color: $color-primary;
              text-decoration: none;
            }

            .date {
              font-size: 13px;
              color: $color-text-grey-medium;
              margin-bottom: 16px;
            }
          }

          .edited-answer {
            display: flex;
            flex-direction: column;
            color: $color-text-medium-2;
            margin-top: 16px;

            span {
              width: 100%;
              margin: 4px 0;
              font-weight: 700;
              font-size: 14px;

              &:last-child {
                margin-bottom: 8px;
              }

              i {
                maring-right: 4px;
              }

              a {
                color: $color-primary;
                text-decoration: none;
              }
            }
          }

          .attachement-container {
            overflow: auto;
            width: 100%;
            flex-shrink: 0;

            .attachement-img {
              width: 100px;
              margin: 2px;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              background-color: $color-grey-light;
              border-radius: $border-radius;

              &::before {
                content: '';
                display: inline-block;
                padding-top: 100%;
              }
            }

            img {
              max-width: calc(100% / 3 - 4px);
              max-height: 100px;
              margin: 2px;
              cursor: pointer;
            }
          }

          .user-sign {
            width: 100%;
            border-top: 1px solid $color-grey-light;
            padding: 16px 8px 16px 0;
            word-break: break-word;
            line-height: 1;/*18px;*/
            font-size: 12px;
            font-family: $font-for-readability;
          }

          .user-buttons {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin: 0 16px;
            padding: 16px 8px 0;
            margin-top: auto;
            align-items: center;

            .btn {
              margin-left: 8px;
            }

            .answer-topic-admin-button {
              display: flex;
            }
            .multi {
              &.active {
                background-color: $color-green;
                color: #FFF;
              }
            }
          }
        }
      }

      .topline-topic {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 16px;

        a,
        a:focus {
          text-decoration: none;
          color: $color-primary;
        }

        img {
          width: 32px;
          height: 32px;
          border-radius: 100px;
        }

        .wrapper-topline {
          display: flex;
          flex-direction: column;
          line-height: 1.2;
          padding-left: 8px;

          a,
          a:focus {
            text-decoration: none;
            color: $color-primary;
          }

          .username {
            font-size: 14px;

            span {
              color: $color-primary;

              a,
              a:focus {
                text-decoration: none;
                color: $color-primary;
              }
            }
          }

          .date {
            font-size: 13px;
            color: $color-text-grey-medium;
          }
        }
      }

      .wrapper-topic {
        padding-bottom: 8px;

        .title {
          font-size: 20px;
          font-weight: bold;
          padding-bottom: 8px;
        }

        .img {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-bottom: 8px;

          img {
            border-radius: $border-radius;
          }
        }

        .text {
          font-size: 16px;
          line-height: 1.2;
        }
      }

      .baseline {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 8px 0;
        border-top: 1px solid $color-grey-light;

        .sign {
          font-size: 13px;
          color: $color-text-grey-medium;
        }
      }

      .answer-group {
        display: flex;
        flex-direction: column;

        &:last-child {
          border-bottom: none;
        }

        .answer-topline {
          //border-bottom: 1px dashed $color-grey-light;
          // border-radius: $border-radius;
          // background-color: rgba(lighten($color-grey-medium, 20), .15);

          .left-side, .right-side {
            // padding: 8px 16px;
          }

          .left-side {
            //background: linear-gradient(to right, #1e5799 0%,#7db9e8 100%);
            // border-radius: $border-radius;
            position: relative;
            // margin-right: 16px;
            z-index: 1;

            .user-information {
              display: flex;
              flex-direction: column;
              justify-content: center;
              max-width: 100%;
              width: 100%;
              min-width: 0;
              z-index: 0;

              .username {
                display: flex;
                font-size: 15px;
                color: #000;
                margin-bottom: 4px;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 600;

                .more-informations {
                  position: relative;
                  margin-left: 8px;
                  font-weight: 400;

                  .icon-question-circle {
                    cursor: pointer;
                    width: 14px;
                    height: 14px;
                  }

                  .more-informations-wrapper {
                    position: absolute;
                    display: none;

                    .member-informations {
                      font-size: 12px;
                      color: $color-grey-medium;
                      padding-right: 16px;
                      line-height: 1.3;
                    }
                  }

                  &:hover {
                    .more-informations-wrapper {
                      display: flex;
                      flex-direction: column;
                      width: 250px;
                      background-color: #FFF;
                      border-radius: $border-radius;
                      padding: 8px;
                      border: 1px solid $color-grey-light;
                    }
                  }
                }

                a {
                  text-decoration: none;
                  //color: $color-primary;
                  color: #000;
                }
              }

              .role {
                font-size: 12px;
                font-weight: 400;
                //color: $color-grey-medium;
                color: #000;
              }
            }

            //&::before {
            //  content: " ";
            //  background: linear-gradient(to right, $color-grey-medium 0%, lighten($color-grey-medium, 20) 100%);
            //  position: absolute;
            //  top:0;
            //  left:-20px;
            //  right:-10px;
            //  bottom:0;
            //  z-index: -1;
            //  transform: skewX(-30deg);
            //  z-index: 0;
            //  border-radius: $border-radius;
            //}
          }

          // &.man {
          //   background-color: rgba($color-gender-man, .15);
          // }

          // &.woman {
          //   background-color: rgba($color-gender-woman, .15);
          // }

          // &.couple {
          //   background-color: rgba($color-gender-couple, .15);
          // }

          // &.couple-lesbian {
          //   background-color: rgba($color-gender-couple-lesbian, .15);
          // }

          // &.couple-gay {
          //   background-color: rgba($color-gender-couple-gay, .15);
          // }

          // &.trav {
          //   background-color: rgba($color-gender-trav, .15);
          // }
        }

        .answer-wrapper {
          display: flex;
          flex-direction: column;
          margin: 8px 0;
          border-bottom: 1px solid darken($v3-color-bg-light-grey, 5);
          .isLike{
            color:$v3-color-like;
          }
          &:last-child {
            border-bottom: none;
          }
          .content {
            display: flex;
            flex-direction: column;
            // padding: 8px 16px;
            font-family: $font-for-readability;
            .answer {
              font-size: .9em;
        line-height: 1.4em;
        color: #000;
              margin: 24px 0;
              @extend .break-long-text;
              font-family: $font-for-readability;
              img {
                max-width: 100%;
                max-height: 800px;
              }
            }

            .modified {
              display: flex;
              flex-direction: column;
              color: $color-text-medium-2;
              margin-top: 16px;

              span {
                width: 100%;
                margin: 4px 0;
                font-size: 12px;

                &:last-child {
                  margin-bottom: 8px;
                }

                i {
                  margin-right: 2px;
                }

                a {
                  color: $color-primary;
                  text-decoration: none;
                }
              }
            }
          }

          .attachement-container {
            overflow: auto;
            width: 100%;
            flex-shrink: 0;

            .attachement-img {
              width: 100px;
              margin: 2px;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              background-color: $color-grey-light;
              border-radius: $border-radius;

              &::before {
                content: '';
                display: inline-block;
                padding-top: 100%;
              }
            }

            img {
              max-width: calc(100% / 3 - 4px);
              max-height: 100px;
              margin: 2px;
              cursor: pointer;
            }
          }

          .user-sign {
            width: 100%;
            border-top: 1px solid $color-grey-light;
            padding: 16px 8px 16px 0;
            word-break: break-word;
            line-height: 1;/*18px;*/
            font-size: 12px;
            font-family: $font-for-readability;
          }

          .answer-topline, .answer-baseline {
            display: flex;
            justify-content: space-between;

            .post-date {
              display: flex;
              align-items: center;
              font-size: 11px;
              color: $color-grey-medium;
              margin-right: 8px;
            }

            .left-side {
              display: flex;
              align-items: center;
              min-width: 0;
              width: 100%;
              .avatar {
                width: 40px;
                height: 40px;
                margin-right: 16px;
              }
            }

            .right-side {
              display: flex;
              align-items: center;

              label {
                font-weight: 400;
                font-size: 12px;
                text-align: right;
              }

              .btn {
                margin-left: 16px;
              }
            }
          }

          .answer-baseline {
            //border-top: 1px solid $color-grey-light;
            margin-bottom: 12px;
          }

          &.isHidden{
            border-radius: $border-radius;
            border: 3px solid $color-red;
            opacity: .8;
            .answer{
              opacity: .5;
            }
          }
        }

        //.answer {
        //  padding: 8px;
        //  background: #F1F3F7;
        //  margin: 8px 0;
        //  border-radius: $border-radius;
        //
        //  .topline-answer {
        //    display: flex;
        //    flex-direction: row;
        //    align-items: flex-start;
        //    padding-bottom: 8px;
        //
        //    .wrapper-topline {
        //      display: flex;
        //      flex-direction: column;
        //      justify-content: flex-start;
        //      align-items: center;
        //      width: 20%;
        //    }
        //
        //    img {
        //      width: 54px;
        //      height: 54px;
        //      border-radius: 100px;
        //    }
        //
        //    .symbols {
        //      display: flex;
        //      flex-direction: row;
        //      justify-content: center;
        //      padding-top: 8px;
        //
        //      .gender {
        //        font-size: 20px;
        //
        //        .icon-mars {
        //          fill: $color-primary;
        //        }
        //
        //        .icon-venus {
        //          fill: $color-main;
        //        }
        //
        //        i {
        //          margin-left: 4px;
        //
        //          &:first-child {
        //            padding-left: 0;
        //          }
        //        }
        //      }
        //    }
        //
        //    .wrapper {
        //      padding-left: 8px;
        //      line-height: 1.2;
        //
        //      .username {
        //        font-size: 13px;
        //        color: $color-primary;
        //
        //        a,
        //        a:focus {
        //          text-decoration: none;
        //          color: $color-primary;
        //        }
        //      }
        //
        //      .timer {
        //        font-size: 13px;
        //        color: $color-text-grey-medium;
        //      }
        //
        //      .wrapper-answer {
        //        padding-top: 8px;
        //
        //        .text-answer {
        //          font-size: 15px;
        //          line-height: 1.2;
        //        }
        //      }
        //    }
        //  }
        //
        //  .baseline {
        //    padding-bottom: 0;
        //    border-top: 1px solid #D2C0BD;
        //  }
        //}
      }

      .form-post-answer {
        padding-top: 8px;

        .form-post-answer-title {
          margin: 8px 0 16px;
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          color: $color-text-medium-2;
          border: 1px solid $color-grey-light;
          border-radius: $border-radius;
          padding: 8px;

          a {
            color: $color-primary;
            text-decoration: none;
          }
        }
      }

      .btn-group {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 16px 0;

        .small {
          cursor: pointer;
        }

        .primary {
          margin: 0 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  

  .loading-modif {
    align-items: center;
    font-size: 15px;
    color: $color-text-medium-2;

    .loader {
      width: unset;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .forums-topic {
    .card {
      margin-top: 0;
    }

    .forums-topic-multiCitation-popup {
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0;
      z-index: 3;
    }

    .forum-card {
      .topline {
        &.subcategory {
          border-radius: 0;
        }
      }

      .topic {
        padding: 16px 8px;

        .content-wrapper {
          .user-content {
            margin: 0;

            .user-buttons {
              flex-wrap: wrap;
            }
          }
        }
      }
    }


    .container {
      padding: 0;

      .topic {
        .topic-header {
          .follow-topic {
            align-items: center;
          }

          .title {
            flex: 1 1;

            .info-topic {
              width: 100%;
              margin-top: 16px;
            }
          }
        }

        .answer-topic {
          margin-top: 8px;
          padding-right: 0;

          .wrapper-settings {
            flex-grow: 1;
            margin-right: 16px;

            .pagination {
              width: 100%;
            }
          }

          .answer-topic-admin-button {
            margin-left: 0;
            margin-right: 8px;

            .small {
              text-align: center;
            }
          }

          .answer-topic-answer-btn {
            margin-left: 8px;
          }
        }
      }
    }

    .form-post-answer {
      .input-group {
        //.btn-group {
        //  justify-content: center;
        //}
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .forums-topic {
    .container {
      .topic {
        .topic-header {
          flex-direction: column;

          .title {
            width: unset;
            flex-direction: column;

            .avatar {
              align-self: center;
              margin: 0;
            }

            .info-topic {
              text-align: center;
              justify-content: center;

              .tag-list {
                justify-content: center;
              }
            }
          }

          .follow-topic {
            width: 100%;
            justify-content: center;

            .back-to-topic {
              position: fixed;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              margin: 0;
              padding: 16px;
              z-index: 2;
              border-radius: 0;
            }

            .dropdown {
              .dropdown-menu {
                width: 100%;
              }
            }
          }
        }
      }

      .poll-display {
        .topline {
          flex-direction: column;
          text-align: center;

          .poll-number-of-vote {
            margin: 8px 0 0 0;
          }
        }
      }

      .topic {
        .content{
          z-index: 0;
        }
        .answer-wrapper {
          .answer-topline {
            .left-side {
              .user-information {
                .username {
                  min-width: 100px;

                  a {
                    @include overflow-ellipsis();
                  }

                  .more-informations {
                    position: unset;

                    &:hover {
                      .more-informations-wrapper {
                        left: 0;
                        width: auto;
                        max-width: 100%;
                        overflow: auto;
                        white-space: normal;
                      }

                    }
                  }
                }
              }
            }
          }

          .answer-baseline {
            //flex-direction: column;

            .left-side {
              .member-informations {
                padding: 0 8px;

                &:first-child {
                  padding-left: 0;
                }

                &:last-child {
                  padding-right: 0;
                }
              }
            }

            .right-side {
              &.responsive-640 {
                display: none;

                &.active {
                  display: flex;
                  flex-direction: column;
                  .dropdown{
                    width: 100%;
                  }
                  .btn {
                    width: 100%;
                    margin-top: 4px;
                    margin-bottom: 4px;
                    margin-left: 0;
                  }
                }
              }
            }
          }

          .answer-baseline {
            //flex-direction: column;
          }
        }

        .answer-topic {
          flex-wrap: wrap;
          margin-top: 8px;
          padding-right: 0;



          .wrapper-settings {
            width: 100%;
            margin-bottom: 8px;

            .pagination {
              width: 100%;
            }
          }

          .btn-group-answer-admin {
            margin: 8px auto;
          }

          .answer-topic-admin-button {
            margin-left: 0;
            flex-grow: 1;
            margin-right: 8px;

            .small {
              width: 100%;
              text-align: center;
            }
          }

          .answer-topic-answer-btn {
            margin-left: 8px;
            flex-grow: 1;
            text-align: center;
          }
        }
      }
    }

    .forum-card {
      .wrapper-settings {
        .pagination {
          width: 100%;
        }
      }

      .topic {
        .content-wrapper {
          flex-direction: column;

          .user-informations {
            width: 100%;
          }

          .user-content {
            width: 100%;
            margin: 0;

            .user-buttons {
              justify-content: flex-start;
              margin: 0;

              .answer-topic-admin-button {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}
