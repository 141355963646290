.view-more-block {
  .view-more-content {
    overflow: hidden;
    white-space: pre-line;
    @extend .break-long-text;
    position: relative;
    font-family: $font-for-readability;
  }

  .view-more-button {
    display: block;
    font-size: 12px;
    color: $color-primary;
    cursor: pointer;
    font-weight: 400 !important;
  }
  &.hide-text{
    .text-gradient{
      position: absolute;
      z-index: 1;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 50px;
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* IE10+ */
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF', endColorstr='#FFF',GradientType=0 ); /* IE6-9 */
      &.comment-gradient{
        background: -moz-linear-gradient(top,  rgba(239,241,245,0) 0%, rgba(239,241,245,1) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(239,241,245,0)), color-stop(100%,rgba(239,241,245,1))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  rgba(239,241,245,0) 0%,rgba(239,241,245,1) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  rgba(239,241,245,0) 0%,rgba(239,241,245,1) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  rgba(239,241,245,0) 0%,rgba(239,241,245,1) 100%); /* IE10+ */
        background: linear-gradient(to bottom,  rgba(239,241,245,0) 0%,rgba(239,241,245,1) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF', endColorstr='#FFF',GradientType=0 ); /* IE6-9 */
      }
    }
  }
}