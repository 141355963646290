#footer {
  display: flex;
  //flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  //background: $color-grey-darken;
  background: #000000;
  margin-top: 16px;

  .wrapper-footer {
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0;
        color: #FFF;
        font-size: .9em;
        &::after {
          content: ' | ';
        }
        &:last-child::after {
          content: '';
        }

        a, a:active {
          color: #FFF;
          padding: 8px 16px;
          text-decoration: none;
        }

        &.not-a-link {
          padding: 16px;
        }
      }
    }
  }

  .copyright-footer {
    font-size: 10px;
    color: #FFF;
  }
}

@media screen and (max-width: $screen-size-medium) {
  #footer {
    padding-bottom: 54px;
    .logo{
      margin: 20px 0;
    }
    .wrapper-footer {
      width: 100%;

      ul {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        li{
          padding: 0px;
        }
      }
    }
  }
  .copyright-footer {
    padding: 8px;
    text-align: center;
    line-height: 14px;
  }
}
