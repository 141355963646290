@import 'src/sass/_variables.scss';

.menu-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  .menu-list-item-loading{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    // opacity: .95;
    border-radius: $v3-default-border-radius;
    color: #FFF;
    background-size: 400% 200%;
    background-image: linear-gradient(to right, $v3-color-primary, $v3-color-secondary, transparentize($v3-color-secondary, .2), $v3-color-secondary, transparentize($v3-color-primary, .1));
    animation: moveGradient 2s infinite;
    .menu-list-item-loading-icon{
        opacity: .8;
    }
}
  .menu-list-item,.menu-list-item-link {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    font-weight: 500;
    cursor: pointer;
    color: #000;
    font-size: .8em;
    letter-spacing: -.015em;
    background-color: $v3-color-bg-light-grey;
    border-radius: $v3-default-border-radius;
    margin-bottom: 8px;
    transition: background-color 0.2s;
    user-select: none;
    min-height: 46px;
    .menu-list-item-icon{
      margin-right: 12px;
    }
    .menu-list-item-check{
        margin-right: 12px;
        height: 22px;
        width: 22px;
        border-radius: 12px;
        border: 1px solid darken($v3-color-bg-light-grey, 13%);
        // background: darken($v3-color-bg-light-grey, 4%);
        display: flex;
        justify-content: center;
        align-items: center;
        .svg-inline--fa{
            font-size: .7em;
            color: #fff;
        }
        &.active{
            background: #000;
            border-color: #000;
        }
    }
    &.active{
      // background: #000;
      // color: #FFF;
      position: relative;
      // border-left: 4px solid #000;
      // padding-left: 16px;
      background-color: darken($v3-color-bg-light-grey, 5%);
      &::before{
        content: '';
        position: absolute;
        left: 0px;
        height: 24px;
        width: 4px;
        background: #000;
        border-radius: 8px;
        // border-radius: 0 4px 4px 0;
      
      }
    }
    &.menu-list-item-link{
      background-color: transparent;
      // &:hover{
      //   text-decoration: underline;
      // }
    }
    &:hover {
      background-color: $v3-color-bg-light-grey-hover;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .menu-list-group{
    // padding: 4px;
    // border: 1px solid $v3-color-bg-light-grey;
    // border-radius: 12px;
    .menu-list-group-title{
        font-size: .7em;
        text-transform: uppercase;
        font-weight: 500;
        color: #000;
        padding: 8px;
        margin-top: 2px;
      }
    .menu-list-item{
        margin-bottom: 1px;
        border-radius: 0;
        &:first-child{
            border-radius: 8px 8px 0px 0px ;
        }
        &:last-child{
            border-radius: 0px 0px 8px 8px;
            margin-bottom: 0;
        }
    }
   }
}
