.album-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-top: 16px;
  margin-bottom: 8px;
  

  .btn-group {
    a {
      text-decoration: none;
    }

    &.back-to-album {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-left: auto;
    }

    .small {
      display: flex;
      align-items: center;

      i {
        margin-right: 4px;
        margin-top: 2px;
      }
    }
  }

  .album-top-infos {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 8px;

    .topline {
      display: flex;
      flex-wrap: wrap;
      margin-right: 16px;

      .avatar {
        width: 70px;
        height: 70px;
        margin-right: 16px;
      }
    }

    .album-description {
      width: 100%;
      color: $color-text-medium-2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .album-grid-btn-group {
    width: 20%;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .topline-profil-albums {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 0 0;

    .topline-profil-albums-btn-group {
      display: flex;
      flex-direction: row;
      margin-left: auto;
    }
  }

  .topline-album {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 0px !important;
    .edit-album {
      margin-left: 8px;
      justify-content: center;
    }
    a, a:focus {
      text-decoration: none;
      color: $color-text-default;
    }

    .private {
      font-size: .8em;
      font-weight: 600;
      box-shadow: none;
      margin-right: 12px;
      text-transform: uppercase;
    }

    .title-album {
      // font-size: 15px;
      // font-weight: bold;
      display: flex;
      //text-transform: capitalize;
      flex: 1;
      flex-direction: column;
      
    }

    .last-album-update {
      color: $color-text-medium-2;
      font-size: 12px;
      margin-top: 4px;
    }
  }

  .album {
    width: 100%;
  }

  .wrapper-album {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 4px 0;
    .wrapper-thumb {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      .thumb {
        width: calc(100% / 5);
        padding: 1px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .thumb-wrapper, img{
          border-radius: 3px;
        }
        .avatar {
          display: none;
        }
  
        .popup {
          .avatar {
            display: flex;
          }
        }
  
        .infos-thumb {
          display: none;
        }
  
        .post-date {
          padding: 0 4px;
        }
        .wrapper{
          padding: 0px;
        }
      }
    }
  }

  .baseline-album {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;

    .report {
      margin-right: 16px;
    }
  }

  &:first-child {
    //margin-top: 0px;
  }
}

@media screen and (max-width: $screen-size-medium) {
  .album-grid {
    .topline-album {
      flex-wrap: wrap;

      a, a:focus {
        max-width: calc(100% - 60px);
        text-decoration: none;
        color: $color-text-default;
      }

      // .title-album {
      //   margin-bottom: 4px;
      // }

      .last-album-update {
        // margin-bottom: 16px;
        margin-left: 0;
        width: 100%;
      }

      .topline-album-options {
        justify-content: space-between;
        margin-left: unset;
        width: 100%;

        .row {
          flex-grow: 1;

          
        }
      }
    }

    .album {
      overflow-x: auto;
      margin: 8px 0;

      .wrapper-album {
        .wrapper-thumb {
          flex-wrap: nowrap;

          .thumb {
            min-width: 100px;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }

          &.album-in {
            flex-wrap: wrap;
          }
        }
      }
    }

    .baseline-album {
      justify-content: space-between;

      a {
        width: 100%;

        .small {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .album-grid {
    margin: 12px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-grey-light;
    .topline-album, .baseline-album {
      padding: 0 8px;
    }
    .btn-group {
      &.back-to-album {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;

        .btn {
          background-color: $color-primary;
          color: #FFF;
          border: 1px solid $color-primary;
          border-radius: 0;
          padding: 8px 16px;
          justify-content: center;
          width: 100%;

          i {
            fill: #FFF;
          }
        }
      }
    }

    .wrapper-album {
      .album-in {
        .thumb {
          width: calc(100% / 2);
          flex-shrink: 0;
        }
      }

      .album-preview {
        .thumb {
          width: calc(90% / 2);
          flex-shrink: 0;
        }
      }
    }
  }
}