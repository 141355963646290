.card-article {
  background: #FFF;
  border-radius: $border-radius;
  .card-article-moderation{
    display: flex;
    justify-content: flex-end;
  }
  .container {
    .btn-group {
      display: flex;
      position: relative;
      padding-bottom: 16px;
      align-items: center;

      .article-blog-location {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;

        a {
          color: $color-primary;
          text-decoration: none;
          text-transform: none;
          font-weight: 400;
        }
      }

      .back {
        background-color: $color-primary;
        color: #FFF;
        user-select: none;
      }
    }

    .wrapper-title-card-article {
      width: 100%;

      .right-btn-group {
        display: flex;
        max-width: 740px;
        margin-left: auto;
        margin-right: auto;
        justify-content: flex-end;
      }

      .card-title-container {
        align-items: center;
        border-radius: $border-radius;
        position: relative;
        padding-bottom: 32px;

        &.published-later {
          background-color: $color-grey-light;
        }

        &.draft {
          background-color: #ffe6e6;
        }

        .badge {
          margin-left: auto;
        }
      }

      .avatar {
        width: 75px;
        height: 75px;
      }

      .display-1 {
        padding-bottom: 8px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        max-width: 740px;
      }

      .wrapper-infos-user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $color-text-grey-medium;
        font-size: 13px;

        .username, .date {
          font-size: 13px;
        }
      }

      .wrapper-picture-card-article {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 24px 0;
      }

      .wrapper-text-article {
        padding-top: 16px;
        max-width: 740px;
        margin: auto;
        line-height: 1.8;
        @extend .break-long-text;
        flex: 1;
        color: $color-text-default;
        font-size: 16px;
        white-space: pre-wrap;
        font-family: $font-for-readability;
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.2;
          margin-top: 8px;
          margin-bottom: 8px;
          color: $color-text-default;
        }

        img, iframe, a {
          max-width: 100%;
          border-radius: 16px;
        overflow: hidden;
        }

        a {
          @extend .break-long-text;
        }
        
        

        .article-paragraph {
          margin-bottom: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid $color-grey-light;

          h3 {
            font-size: 20px;
            margin: 12px 0;

            &:first-child {
              margin-top: 0;
            }
          }

          p {
            line-height: 1.2;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  .article-stats {
    padding-top: 16px;
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    color: $color-text-default;
    .isLike{
      color:$v3-color-like;
    }
    .article-stats-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .article-stats-wrapper-views{
      margin-left: 8px;
      font-size: 0.9em;
    }
    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 150px;
      margin: 0 auto;
      background-color: lighten($color-text-medium-2, 30);
      margin-bottom: 16px;
    }

    .tag-list {
      justify-content: center;
    }

    .stats {
      .likes, .subjects, .answers-count {
        position: relative;
        top: unset;
        right: unset;
        color: $color-text-medium-2;
        padding: 4px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        background-color: transparent;
      }


      .views {
        position: relative;
        top: unset;
        left: unset;
        color: $color-text-medium-2;
        background-color: transparent;
      }
    }

    .report {
      position: absolute;
      right: 0;
    }
  }

  .profil-link, .profil-link:focus {
    color: $color-primary;
    text-decoration: none;
  }

  .title-link-article, .title-link-article:focus {
    text-decoration: none;
    color: $color-text-default;
  }
}

@media screen and (max-width: $screen-size-medium) {
  .card-article {
    .container {
      .wrapper-title-card-article {
        .title {
          flex-wrap: wrap;

          .badge {
            margin-bottom: 16px;
          }

          span {
            width: 100%;
          }
        }

        .wrapper-text-article {
          padding: 8px;
          padding-top: 16px;
          line-height: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .card-article {
    .card-title-container {
      flex-direction: column;
      align-items: center;

      .avatar-container {
        margin-right: 0;
        margin-bottom: 16px;
      }

      .badge {
        margin-right: auto;
        margin-bottom: 16px;
      }

      .title {
        justify-content: center;

        span {
          text-align: center;
        }
      }

      .wrapper-infos-user {
        justify-content: center;
      }
    }

    .container {
      .back-button {
        padding: 0;

        .back {
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          text-align: center;
          width: 100%;
          border-radius: 0;
          border: 0;
          padding: 16px 8px;
        }
      }

      .wrapper-title-card-article {
        .right-btn-group {
          justify-content: center;
        }
      }
    }

    .article-stats {
      .article-stats-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .report {
          position: relative;
        }
      }
    }
  }
}
