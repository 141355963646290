#userStatList {
  /*max-height: 350px;
  max-width: 500px;
  overflow: hidden;*/
  max-height: 350px;
  /* max-width: 500px; */
  overflow-y: scroll;
  background: #FFF;

  .container {
    width: 500px;
    height: 300px;

    .card {
      display: flex;
      flex-wrap: wrap;
      padding: 0;

      .topline {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid $color-grey-light;
        font-size: 15px;

        .userStatList-username {
          font-weight: 700;
          font-size: 14.5px;
        }

        .icon-close {
          cursor: pointer;
        }
      }

      .user-stat-list {
        width: 100%;

        .user-stat-list-container {
          height: calc(350px - 98px);
          overflow: auto;
          width: 100%;

          li {
            display: flex;
            align-items: center;
            list-style-type: none;
            padding: 8px 16px;
            color: $color-text-default;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;

            a {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              text-decoration: none;
              color: $color-primary;
            }

            .avatar {
              margin-right: 8px;
              width: 32px;
              height: 32px;
              border-radius: $border-radius-rounded;
            }

            &:hover {
              background-color: $color-grey-light;
            }
          }
        }
      }
    }
  }
}
