#topUsers {
  margin-top: 16px;

  .container {
    background-color: #FFF;
    border-radius: $border-radius;

    .main-wrapper {
      padding: 8px;

      h3 {
        font-size: 25px;
        margin-top: 8px;
        margin-bottom: 16px;
      }

      .btn-group {
        .small {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: $color-text-default;

          .icon-svg {
            fill: $color-text-default;
            margin-right: 4px;
          }
        }

        .menu-item {
          display: flex;
          flex-wrap: wrap;

          label {
            width: 100%;
            margin-bottom: 8px;
          }

          select {
            width: 100%;
          }
        }
      }

      .list {
        .user {
          display: flex;
          align-items: center;
          margin: 8px 0;

          .avatar {
            width: 32px;
            height: 32px;
            margin-right: 16px;
          }

          .infos {
            display: flex;
            flex-direction: column;

            span {
              &.username {
                font-size: 14px;
                color: $color-primary;
                font-weight: 700;

                a {
                  color: $color-primary;
                  text-decoration: none;
                }
              }

              margin: 2px 0;
              color: $color-text-medium-2;
              font-size: 13px;
            }
          }

          .contact {
            margin-left: auto;

            a {
              color: $color-primary;
              margin: 8px 16px;
              transition: opacity ease-in-out .1s;

              i {
                width: 15px;
                fill: $color-primary;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  .top-user-pagination{
    display: flex;
    justify-content: center;
    .btn{
      margin: 0 8px 8px 8px;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  #topUsers {
    .container {
      .main-wrapper {
        .filters {
          flex-wrap: wrap;
        }

        .list {
          .user {
            .contact {
              a {
                margin: 8px;
              }
            }
          }
        }
      }
    }
  }
}
