.show-upload {
  .global-progress-bar {
    width: 100%;
    height: 3px;
    background-color: $color-grey-light;
    position: relative;
    overflow: hidden;

    .global-progress-bar-inside {
      height: 3px;
      width: 0%;
      background-color: lighten($color-green, 7);
      position: absolute;
      left: 0;
      top: 0;
      transition: width .2s linear;
    }
  }

  .uploading-file-wrapper {
    max-height: 100px;
    overflow: auto;

    .upload-summarize-block {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      margin: 4px 0;
      padding: 4px 16px;

      .no-file {
        font-size: 13px;
        color: $color-text-medium-2;
      }

      .upload-summarize-title {
        display: block;
        font-size: 12px;
        line-height: 13px;
        margin-bottom: 4px;
        font-weight: 700;
        flex-shrink: 0;
        @include overflow-ellipsis();
      }

      .progress-bar-small {
        flex-shrink: 0;
        width: 100%;
        height: 4px;
        border-radius: $border-radius;
        background-color: $color-grey-light;
        position: relative;
        overflow: hidden;

        .progress-bar-inside {
          width: 0%;
          background-color: lighten($color-green, 7);
          transition: width .2s linear;
          position: absolute;
          top: 0;
          left: 0;
          height: 4px;
        }
      }

      .file-data {
        margin-top: 4px;
        font-size: 11px;
        color: $color-text-medium-2;
      }
    }
  }
}