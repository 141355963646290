// SINGLE ELEMENT SPINNER

.loader {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 2px solid darken($color-grey-light, 15);
  border-radius: 50%;
  animation: spin 0.75s infinite linear;

  &::before,
  &::after {
    left: -2px;
    top: -2px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
  }

  &.small {
    width: 20px;
    height: 20px;
  }

  &.big {
    width: 60px;
    height: 60px;
    margin: 64px auto;
  }
}


.outlet-loader{
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spin-loader,
.spin-loader::before {
  display: block;
  border-color: transparent;
  border-top-color: darken($color-grey-light, 15);
}

.spin-loader::before {
  height: 100%;
  width: 100%;
  animation: spin 1.5s infinite ease;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.infinite-loading-container{
  position: relative;
  .infinite-loading-bar {
    height: 4px;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background-color: $color-grey-light;
    border-radius: 2px;
    &::before{
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 4px;
      background-color: $color-primary;
      animation: infinite-loading-bar 2s linear infinite;
    }
  }
}

@keyframes infinite-loading-bar {
  from {left: -200px; width: 20%;}
  90% {width: 70%;}
  80% { left: 80%;}
  95% {left: 120%;}
  to {left: 100%;}
}


.loading-suspense {
  position: fixed;
  //background-color: #ff84a6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}/*
.loading-suspense::before {
  content: '';
  background-image: url("../../../public/splash-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
  -webkit-animation: 1.2s ease-in 0s infinite alternate img_scale;
  animation:         1.2s ease-in 0s infinite alternate img_scale;
}*/
@media screen and (max-width: 768px) {
  .loading-suspense::before {
    width: 15vw;
    height: 15vw;
  }
}
@-webkit-keyframes img_scale { from { transform: scale(1); } to { transform: scale(1.8); }  }
@keyframes img_scale { from { transform: scale(1); } to { transform: scale(1.8); }  }