#warningHead {
  background-color: #FFF;
  padding: 16px;
  border-bottom: 1px solid #FFF;

  .container {
    color: #FFF;
    text-align: center;
    font-size: 14px;

    .btn {
      margin-left: 16px;
    }
  }

  &.error {
    background-color: $color-red;
  }

  &.success {
    background-color: $color-green;
  }

  &.primary {
    background-color: $color-primary;
  }
}