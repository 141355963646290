.lexical-emoji{
  color: #000000;
  position: absolute;
  right: 0px;
  top: 0px;
  .lexical-emoji-picker{
    position: absolute;
    z-index: 9999;
    bottom: 40px;
    right: 8px;
  }
  .lexical-emoji-button{
    user-select: none;
    width: 36px;
    height: 36px;
    align-items: center;
    display: flex;
    //background: red;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
    &:active{
      background: rgba(0,0,0,0.1) !important;
    }
    &:hover{
      background: rgba(0,0,0,0.05);
    }
  }
  .lexical-emoji-button-icon{
    font-size: 18px;
  }
}

