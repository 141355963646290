.page-title{
    a{
        color: inherit;
        text-decoration: inherit;
    }
}

h1.page-title{
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 1.5em;
    line-height: 1.2em;
    letter-spacing: -.02em;
    font-weight: 600;
    color: #000;
}

h2.page-title{
    font-size: 1.25em;
    line-height: 1.2em;
    letter-spacing: -.02em;
    font-weight: 600;
    color: #000;
}

h3.page-title{
    font-size: 1.1em;
    line-height: 1.3em;
    letter-spacing: -.02em;
    font-weight: 500;
    color: #000;
}