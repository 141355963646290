#change-picture {
  max-width: 1200px;
  width: 1200px;
  background-color: #FFF;
  max-height: 90vh;
  height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;

  .change-picture-wrapper-selection {
    display: flex;
    flex-direction: column;
    //overflow: scroll;
    flex-grow: 1;

    .change-picture-upload-pic {
      position: relative;
      border: 1px solid $color-primary;
      background-color: lighten($color-primary, 45%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 16px 8px;
      //margin: 16px 16px 0 16px;
      border-radius: $border-radius;
      color: $color-primary;
      cursor: pointer;
      flex-shrink: 0;
      min-height: 108px;

      .crop-picture {
        height: 70vh;
        justify-content: center;
        align-items: center;
        display: flex;
        // overscroll-behavior-y: contain;
        img {
          all: unset;
          max-height: 70vh;
          max-width: 90vh;
          width: auto;
          margin: 0;
          display: block;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .up-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $border-radius-rounded;
          border: 3px solid $color-primary;
          margin-bottom: 8px;
          width: 50px;
          height: 50px;

          i {
            width: 20px;
            fill: $color-primary;
          }
        }

        h4 {
          margin-bottom: 8px;
        }

        &.infos {
          align-items: flex-start;

          span {
            font-size: 14px;
          }
        }
      }

      input {
        opacity: 0;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        width: 100% !important;
        height: 100% !important;
      }

      &.hidden {
        display: none;
      }

      &.avatar {
        width: 116px;
        height: 116px;
        border-radius: $border-radius-rounded;
        overflow: hidden;
        margin: 16px auto 0 auto;
        padding: 8px;

        .content {
          display: flex;
          justify-content: center;
          margin: 8px;

          .up-arrow {
            border: unset;
            width: unset;
            height: unset;
          }

          span {
            font-size: 12px;
          }
        }

        .avatar-img-preview {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border: 2px solid #FFF;
          border-radius: $border-radius-rounded;
        }
      }

      &.cover {
        &.active {
          padding: 8px;
        }
      }
    }

    .change-picture-album-pic {
      display: flex;
      justify-content: center;
      border-radius: $border-radius;
      width: 100%;
      flex-grow: 1;
      color: #FFF;

      .change-picture-media {
        width: 100%;

        .change-picture-media-container {
          padding: 0;
          width: 100%;

          .navbar {
            display: inline-flex;
            margin: 16px 0 16px 16px;
            width: calc(50% - 16px);

            li {
              display: flex;
              font-weight: 700;
              font-size: 13px;
              color: $color-text-medium-2;
              padding: 8px 16px;
              border-radius: $border-radius;
              cursor: pointer;
              user-select: none;

              span {
                margin-left: 8px;
                background-color: $color-primary;
                color: #FFF;
                border-radius: $border-radius-rounded;
                height: 14px;
                width: 14px;
                display: none;
                justify-content: center;
                align-items: center;
                font-size: 11px;

                &.active {
                  display: flex;
                }
              }

              &.active {
                background-color: $color-grey-light;
              }
            }
          }

          input[type="text"] {
            display: inline-flex;
            width: calc(50% - 16px);
            margin: 0 16px 16px 0;
          }

          .change-picture-photo-grid {
            height: 297px;
            padding: 16px;
            flex-wrap: wrap;
            display: none;

            position: relative;

            .photo-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: calc((100% / 8) - 14.5px);
              margin: 8px 16px 8px 0px;
              position: relative;
              padding: 8px;
              cursor: pointer;

              .photo-thumb {
                height: 80px;
                width: 80px;
                position: relative;
                border-radius: $border-radius;
                overflow: hidden;

                .btn-play {
                  position: absolute;
                  height: 32px;
                  width: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  bottom: 4px;
                  right: 4px;
                  background: #FFF;
                  border-radius: 50px;

                  i {
                    color: $color-primary;
                  }
                }
              }

              .title {
                margin-top: 8px;
                font-size: 12px;
                color: $color-text-medium-2;
                @extend .break-long-text;
              }

              &:nth-child(8n) {
                margin-right: 0;
              }
            }

            &.active {
              display: flex;
            }
          }

          .change-picture-album-grid {
            overflow: scroll;
            height: 297px;
            flex-wrap: wrap;
            display: none;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            padding: 16px;

            .btn-play {
              display: none;
            }

            .grid {
              display: flex;
              flex-wrap: wrap;

              .photo-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: calc((100% / 8) - 14.5px);
                margin: 8px 16px 8px 0px;
                position: relative;
                padding: 8px;
                cursor: pointer;

                .photo-thumb {
                  height: 80px;
                  width: 80px;
                  position: relative;
                  border-radius: $border-radius;
                  overflow: hidden;

                  .btn-play {
                    position: absolute;
                    height: 32px;
                    width: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    bottom: 4px;
                    right: 4px;
                    background: #FFF;
                    border-radius: 50px;

                    i {
                      color: $color-primary;
                    }
                  }
                }

                .title {
                  margin-top: 8px;
                  font-size: 12px;
                  color: $color-text-medium-2;
                  @extend .break-long-text;
                }

                &:first-child {
                  margin-left: 0;
                }

                &:nth-child(8n) {
                  margin-right: 0;
                }

                &:nth-child(9n) {
                  margin-left: 0;
                }

                &.active {
                  background-color: lighten($color-primary, 40%);
                }
              }
            }

            .wrapper {
              width: 100%;

              .btn-group {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
              }
            }

            .album-block {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              position: relative;
              width: calc((100% / 7) - 28px);
              margin: 16px 32px 16px 0;
              padding: 8px;

              .album-thumb {
                position: relative;
                height: 96px;
                width: 96px;
                margin-bottom: 16px;
                user-select: none;
              }

              .title {
                font-size: 12px;
                color: $color-text-medium-2;
              }

              .thumb {
                position: absolute;
                box-shadow: $box-shadow-light;
                border-radius: $border-radius;
                overflow: hidden;

                &:nth-child(1) {
                  z-index: 3;
                  left: 0;
                  top: 0;
                }

                &:nth-child(2) {
                  z-index: 2;
                  left: 8px;
                  top: 8px;
                  opacity: 0.8;
                }

                &:nth-child(3) {
                  z-index: 1;
                  left: 16px;
                  top: 16px;
                  opacity: 0.6;
                }
              }

              &:nth-child(7n) {
                margin-right: 0;
              }

              &.active {
                background-color: lighten($color-primary, 40%);
              }
            }

            &.active {
              display: flex;
            }
          }
        }
      }

      &.hidden {
        display: none;
      }
    }
  }

  #uploads {
    display: none;
  }

  &.active {
    overflow: scroll;

    #uploads {
      display: flex;
    }
  }

  .upload-btn {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: auto;
    font-size: 12px;
    padding: 16px 8px;
    background-color: #FFF;
    z-index: 4;

    .row {
      justify-content: flex-end;
    }

    .align-end {
      margin-left: auto;
    }

    .in-progress {
      flex-grow: 1;
      width: unset
    }

    .small {
      margin: 8px;
    }

    .reset-selection {
      color: $color-red;
    }

    .upload-ended {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      i {
        margin-left: 4px;
        fill: $color-green;
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  #change-picture {
    width: 100vw;
    height: 100%;
    max-width: unset;

    .change-picture-wrapper-selection {
      overflow: visible;

      .change-picture-upload-pic {
        .crop-picture {
          img {
            all: unset;
            max-height: 75vh;
            max-width: 90vw;
            width: auto;
            margin: 0;
            display: block;
          }
        }

        &.active {
          height: 100vh;
          max-height: calc(100vh - 140px);
        }
      }

      .change-picture-album-pic {
        .change-picture-media {
          .change-picture-media-container {
            .navbar {
              width: calc(100% - 16px);
              margin: 8px;

              li {
                flex-grow: 1;
              }
            }

            input[type="text"] {
              width: calc(100% - 16px);
              margin: 0 8px;
            }

            .change-picture-photo-grid {
              height: calc(100vh - 292px);
              overflow: auto;

              .photo-block {
                margin: 0 8px;
                width: calc(100% / 3 - 16px);

                .title {
                  word-break: break-all;
                }
              }
            }

            .change-picture-album-grid {
              height: calc(100vh - 292px);
              overflow: auto;

              .grid {
                .album-block {
                  margin: 0px 8px;
                  width: calc(100% / 3 - 16px);

                  .title {
                    word-break: break-all;
                  }
                }
              }
            }
          }
        }
      }

      &.mobile-hide {
        overflow: hidden;
      }
    }

    .upload-btn {
      padding: 8px;
    }
  }
}
