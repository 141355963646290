.card-controls {
  width: 100%;
  padding: 8px;
  padding-top: 16px;

  .head-title, h3 {
    user-select: none;
    justify-content: flex-start;

    div {
      display: inline-block;
    }

    span {
      margin: 0px 8px;
      color: #cfcfcf;
      transition: all .1s linear;

      &:first-child {
        margin-left: 0px;
      }

      &:hover, &.active {
        cursor: pointer;
        color: $color-primary;
      }
    }
  }

  .head-title {
    margin-bottom: 16px;
  }

  .main-controls-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .head-title {
      margin-bottom: 0;
    }

    .filters {
      display: flex;
      align-items: center;

      .dropdown {
        margin-right: 16px;

        .menu-item {
          justify-content: space-between;
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-sliders {
        margin-right: 8px;
      }
    }
  }

  .sub-controls-row {
    display: flex;
    min-height: 32px;
    margin-top: 16px;

    h3 {
      width: unset;
    }

    .select-wrapper {
      margin: 0 16px;

      select {
        padding: 0 32px 0 16px;
      }
    }

    .btn-search-period {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .filter-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .filters {
      display: flex;

      .dropdown {
        margin-right: 16px;

        .icon-sliders {
          margin-right: 4px;
        }

        .icon-chevron-down {
          margin-left: 4px;
          width: 10px;
        }

        &:last-child {
          margin-right: 0;
        }

        .dropdown-children {
          .ui-light {
            font-size: 14px !important;
            display: flex;

            &:hover, &.active {
              .icon-sliders, .icon-chevron-down {
                fill: $color-primary;
              }
            }
          }
        }

        .dropdown-menu {
          width: 250px;

          .menu-item {
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
  }
}

@media screen and (max-width: $screen-size-medium) {
  .card-controls {
    .head-title, h3 {
      text-align: left;
      align-items: center;
      overflow: auto;
      display: flex;

      div {
        flex-shrink: 0;
      }

      span {
        flex-shrink: 0;
        padding: 0 8px;
        margin: 0;
      }
    }

    .head-title {
      font-size: 22px;
    }

    h3 {
      font-size: 18px;
      font-weight: normal;
      width: 100%;

      span {
        &:first-child {
          padding-left: 0;
        }
      }
    }

    .filter-options {
      flex-wrap: wrap;

      .filters {
        width: 100%;
        justify-content: flex-end;

        .dropdown {
          //width: 100%;

          .dropdown-children {
            width: 100%;

            .small {
              width: 100%;
              justify-content: center;
            }
          }

          .dropdown-content {
            height: unset;
            top: unset;
          }

          .dropdown-menu {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .card-controls {
    .main-controls-row {
      .filters {
        margin-top: 8px;
        flex-grow: 1;
      }
    }
    .sub-controls-row {
      flex-wrap: wrap;

      .select-wrapper {
        margin: 8px 0 0 0;
        flex-grow: 1;
      }
    }
  }
}