#signin-klingon-container {
  padding: 20px 0;
  margin-bottom: -16px;

  .btn {
    transition: all 0.3s;
    &:hover {
      background: $color-primary;
      color: white;
    }
  }

  .signin-klingon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding : 0 10px;
  }

  .signin {
    color: #FFF;
    flex: 1;

    h2 {
      font-weight: bold;
      font-size: 37px;
      color: #FFF;
    }

    h3 {
      margin: 24px 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.3em;
      color: #FFF;
    }
  }


  .klingon {
    background: #cbcbcb;
    height: 90px;
    width: 728px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../images/images-test/175x175/10.jpeg');
    background-size: cover;
    color: #FFF;
    font-weight: bold;
  }
}

@media screen and (max-width: $screen-size-medium) {
  #signin-klingon-container {
    flex-direction: column;

    .signin-klingon-wrapper {
      flex-direction: column;
    }


    .signin {
      color: $color-text-default;
      margin-bottom: 32px;
    }

    .klingon {
      width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #signin-klingon-container {
    flex-direction: column;
    background-color: $color-main;
    padding: 16px 0;

    .signin {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color-text-default;
      margin-bottom: 0;

      h2 {
        color: #FFF;
        margin-bottom: 16px;
        font-size: 24px;
        text-align: center;
      }

      h3 {
        margin-top: 0;
        font-size: 16px;
        line-height: 1;
        color: #FFF;
        margin-bottom: 16px;
        padding: 0 8px;
        text-align: center;
      }

      .btn-rounded {
        font-size: 13px;
      }
    }

    .klingon {
      display: none;
    }
  }
}