#tag-search {
  .btn-responsive {
    display: none;
  }

  .mt-24{
    margin-top: 24px;
  }
  .photo-tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    overflow: auto;
    width: 100%;

    //li {
    //  margin-right: 16px;
    //  line-height: 1.4;
    //  font-size: 24px;
    //  color: #D36C9D;
    //  cursor: pointer;
    //  border-bottom: 2px solid transparent;
    //  position: relative;
    //  user-select: none;
    //
    //  &::after {
    //    content: "";
    //    display: block;
    //    position: absolute;
    //    bottom: -2;
    //    width: 100%;
    //    height: 0px;
    //    background-color: #D36C9D;
    //    transition: height .2s linear, transform .2s linear;
    //    transform: scaleX(0);
    //  }
    //
    //  &.active {
    //    &::after {
    //      height: 2px;
    //      transform: scaleX(1);
    //    }
    //  }
    //}
  }

  .card-controls{
    .sub-controls-row{
        h3{
          a{
            margin: 0 8px;
            color: #cfcfcf;
            font-size: 20px;
            -webkit-transition: all .1s linear;
            transition: all .1s linear;
            &.active {
              cursor: pointer;
              color: #2276f4;
            }
            &:first-child {
              margin-left: 0px;
            }
          }

        }


    }
  }



  .thumbs-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //margin: -8px 0;
    .thumb {
      width: calc(100% / 5);
      padding: 8px;

      .avatar {
        width: 32px;
        height: 32px;
      }
    }
  }

  .pagination {
    margin-top: 32px;
  }
}

@media screen and (max-width: $screen-size-medium) {
  #tag-search {
    .photo-tags-wrapper {
      flex-wrap: nowrap;
      overflow: hidden;
      overflow-x: auto;

      li {
        white-space: nowrap;
      }
    }

    .container {
      padding: 0;
    }

    .thumbs-grid {
      .thumb {
        width: calc(100% / 4);
        max-width: calc(100% / 4);
      }

      .pagination {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: $screen-size-small) and (max-width: $screen-size-between-small-tablette) {
  #tag-search {
    .thumbs-grid {
      .thumb {
        width: calc(100% / 3);
        max-width: calc(100% / 3);
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #tag-search {

    .thumbs-grid {
      .thumb {
        width: 100%;
        max-width: 100%;
        padding: 8px 0;

        &.thumb-video {
          max-width: 100%;
        }
      }
    }
  }
  .pagination {
    flex-grow: 1;
  }
}

@media screen and (max-width: $screen-size-min) {
  #tag-search {
    .thumbs-grid {
      justify-content: center;

      .thumb {
        width: 100%;
        max-width: calc(100% - 8px);
        margin: 8px 0;
      }
    }
  }
}
