@import "src/sass/variables";

#albumInformations {
  width: 100vw;
  max-height: 100vh;
  overflow: auto;
  padding: $v3-padding-drawer;

  .album-informations-footer{
    display: flex;
    justify-content: flex-end;
  }

  .album-datas-wrapper {
    .tag-wrapper{
      margin-top: 8px;
    }
    .informations, .tag-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 50%;

      .custom-textarea{
        margin: 4px 0;
        height: 85px;
        resize: vertical;
        border: 1px solid $color-grey-light;
        border-radius: $border-radius;
        padding: 8px;
        overflow: auto;
        .DraftEditor-root{
          height: 100%;
        }
      }

      .category-title {
        display: flex;
        color: $color-text-default;
        font-size: 14px;
        margin-bottom: 12px;

        i {
          fill: $upload-grey;
          margin-right: 6px;
        }
      }

      .error-text {
        color: $color-red;
        font-size: 12px;
        line-height: 1.3333;
      }

      .filter {
        border-color: $upload-grey;

        li {
          height: auto;
          flex-grow: 0;
          flex : 1;
          text-align: center;
          &.active {
            background-color: $upload-grey;
          }
          &.invalid{
            color: $color-red;
          }
        }

        &.error {
          border-color: $color-red;
        }
      }

      .album-options-checkbox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 4px 0;
        .disable{
          text-decoration: line-through;
          color: gray;
        }
        .custom-checkbox-input{
          margin: 4px 0;
          label {
            font-size: 14px;
          }
        }
      }

      .temperature-information {
        font-size: 12px;
        color: $color-text-default;
        line-height: 1.3333;
        &.invalid{
          color : $color-red;
        }
      }

      .filter, .temperature-information, input, textarea, .error {
        margin: 4px 0;
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  #albumInformations {
    max-width: unset;
  }
}

@media screen and (max-width: $screen-size-small) {
  #albumInformations {
    height: 100%;

    .tag-wrapper {
      margin-top: 16px;
    }
  }
}