.carousel {
  position: relative;
  min-height: 80px;

  .panes-wrapper {
    overflow: hidden;
  }

  .panes {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-left: 0;
    transition: margin .5s ease;

    .pane {
      width: 100%;
      max-width: 100%;
      height: 500px;

      &.active {
        height: 100%;
      }
    }
  }

  .arrow-wrapper {
    position: absolute;
    top: 0px;
    bottom: 0px;
    display: none;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    transition: all .3s ease;
    transform: scale(.75);
    z-index: 1;

    .arrow {
      background: $color-primary;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #FFF;
      width: 30px;
      border-radius: 100px;
      box-shadow: $box-shadow-1;
      height: 40%;
      min-height: 80px;

      i {
        width: 32px;
        fill: lighten($color-primary, 25);
      }

      &:active {
        transform: scale(.93);
      }
    }

    &.show {
      display: flex;
      opacity: 1;
      transform: scale(1);
      cursor: pointer;
    }

    &.previous {
      left: -39px;

      .arrow {
        background: linear-gradient(90deg, $color-primary, darken($color-primary, 5));
        border-radius: 8px 2px 2px 8px;
      }
    }

    &.next {
      right: -39px;

      .arrow {
        border-radius: 2px 8px 8px 2px;
        background: linear-gradient(90deg, darken($color-primary, 5), $color-primary);

      }
    }
  }
}
