#moderation {
  .display-2 {
    margin-bottom: 16px;
  }

  .sidebar {
    border-right: 1px solid $color-grey-light;
  }

  .main-wrapper {
    padding-left: 16px;
    padding-right: 0;
    min-height: calc(100vh - 174px);

    .media-container {
      display: flex;
      flex-wrap: wrap;

      .block {
        display: flex;
        flex-direction: column;
        width: calc(100% / 3 - 16px);
        min-width: 200px;
        max-width: calc(100% / 3 - 16px);
        margin: 8px;
        padding: 8px;
        flex-shrink: 0;
        cursor: pointer;
        border-bottom: 1px solid $color-grey-light;
        overflow: hidden;
        flex-grow: 1;
        box-shadow: $box-shadow-light;
        border-radius: $border-radius;

        .img-container {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 100%;
          height: 150px;
          border-radius: $border-radius $border-radius 0 0;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          overflow: hidden;
          background-color: $color-grey-light;

          .article-content, .status-content {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 25px;
            color: lighten($color-text-medium-2, 30);
            font-weight: 600;
            text-transform: uppercase;
            line-height: 16px;
          }

          .status-content {
            top: unset;
          }

          .badge {
            position: absolute;
            top: 8px;
            right: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  #moderation {
    .container {
      padding: 0;

      .card {
        margin-top: 0;

        .sidebar {
          margin-bottom: 16px;
        }

        .main-wrapper {
          padding-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #moderation {
    .main-wrapper {
      .media-container {
        .block {
          max-width: 100%;
        }
      }
    }
  }
}
