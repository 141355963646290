.card-subcategory {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px dashed $color-grey-light;

  a, a:focus {
    color: #000;
    text-decoration: none;
  }

  .wrapper-left {
    display: flex;
    flex: 1;

    .unread {
      padding: 7px 16px 0 0;
      height: 100%;
    }

    .wrapper-card-subcategory {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      .wrapper-title {
        line-height: 1.2;
        display: flex;
        flex-wrap: wrap;
        width: 88%;
        display: flex;
        flex-direction: column;

        .title {
          color: #000;
          font-size: 18px;
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          font-family: $font-for-readability;

          a {
            color: #000;
            font-family: $font-for-readability;
          }

          .row {
            width: 100%;

            input {
              width: 100%;
              border-bottom: 2px solid $color-primary;
              font-weight: bold;
            }

            .btn-rounded-corner {
              margin: 0 8px;
              cursor: pointer;
            }
          }
        }

        .started-by {
          font-size: 13px;
          color: $color-text-medium-2;
        }

        .tag-list {
          margin-left: 8px;

          .tag {
            padding: 2px 4px;
          }
        }

        .pagination {
          margin: 0px 8px;
          padding-top: 2px;
          border: 0;
          background-color: transparent;

          .pagination-btn-group {
            border: 0;
            background-color: transparent;

            .pagination-btn {
              font-size: 8px;
              padding: 0px 12px;
            }
          }
        }
      }

      .wrapper-infos {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        line-height: 1.2;

        .subjects, .answers, .views-text {
          font-size: 13px;
          color: $color-text-medium-2;
          margin: 2px 0;

          i {
            fill: $color-text-medium-2;
            margin-left: 4px;
          }

          span {
            margin-right: 0;
          }
        }
      }
    }
  }

  .last-post {
    display: flex;
    flex-direction: row;
    width: 250px;
    padding-left: 16px;
    align-items: center;
    overflow: hidden;
    &.full{
      width: initial;
    }
    .avatar {
      width: 42px;
      height: 42px;
    }

    .wrapper-infos-last {
      padding-left: 16px;
      line-height: 1.2;
      color: $color-primary;
      font-size: 12px;

      .link span {
        display: block;
      }

      .username-last-post {
        margin-bottom: 2px;
      }
    }
  }

  .topic-moderation {
    margin-left: auto;
    cursor: pointer;
  }
}

@media screen and (max-width: $screen-size-tablette) {
  .card-subcategory {
    .badge {
      margin: 0 0 8px 0;
    }
  }
}
