#facettes {
  .facettes-container { 
    display: grid;
    grid-template-columns: $size-sidebar 1fr;
    @media screen and (max-width: $screen-size-small) {
      display: block;
    }    
  }
  .cta-all-medias{
    display: flex;
  }
  .text-footer{
    padding: 16px;
    width: 100%;
    font-size: 14px;
    line-height: 1.4;
    margin-top: 16px;
    //font-weight: 300;
    p{
      font-family: inherit;
      font-weight: 400;
      font-size: .9rem;
      line-height: 1.4;
      margin-bottom: 0.8333333333rem;
      text-rendering: optimizeLegibility;
    }

    a {
      //color: black;
      color : $color-primary;
      font-weight: bold;
      text-decoration: none;
    }
    .title{
      display: flex;
      justify-content: center;
      font-size: .9rem;
      background-color: rgba(51,51,51,.1);
      padding: 0.25rem 0.5rem;
      border-radius: 3px;
    }
    .text-info{
      color : $color-primary;
    }
    .text-danger{
      color : #dd0505;
    }
    .text-warning{
      color : #db8100;
    }
    .text-underlined{
      text-decoration: underline;
    }
    h1{
      font-size: 20px;
      margin: 8px 0;
    }
    h2{
      font-size: 18px;
    }
    h3{
      font-size: 16px;
    }
    h2,h3,h4{
      margin: 4px 0;
    }
    ul{
      margin: 4px 0;
      li{
        &:before{
          content:"- "
        }
      }
    }
  }

  /* en mobile .facettes-container ne contient pas .sidebar */
  .sidebar {
    padding-right: 8px;
    box-sizing: border-box;
    font-size: .85em;
    color: #343434;

    &.hidden{
      display: none;
    }

    .categories {      

      &> h4 {
        color: $color-primary;
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 16px;
      }

      &> ul {
        max-height: 235px;
        overflow-y: auto;

        /* width */
        &::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey; 
          border-radius: 10px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #97a8c3; 
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #7f9fd2; 
        }
        &> li {
          margin-bottom: 10px;


          &> input {
            &[type="checkbox"]:checked + label a {
              color: $color-primary;
            }
          }
          &> label a {
            color: #343434;
            transition: color 0.3s;
            &:hover {
              color: $color-primary;
            }
          }
          .uri-active{
            cursor: pointer;
            color: $color-primary;
          }
          .uri:hover{
            cursor: pointer;
            color: $color-primary;
          }
        }
      }
    }
  }

  .results-panel {
    h1 {
      color: #343434;
      font-size: 24px;
    }
    .results-count {
      font-size: 12px;
    }
    .affiner-button {
      margin-top: 16px;
    }
    .suggestions-sentence {
      margin-top: 16px;
      font-style: italic;
      font-size: 12px;
      margin-bottom: 4px;
    }
    .suggestions-group{
      margin-bottom: 16px;
      a{
        margin: 0 8px 8px 0;
        display: inline-block;
      }
      .no-uri{
        margin: 0 8px 8px 0;
        display: inline-block;
      }
    }
    .group-pagination{
      a.pagination-btn {
        flex: 1;
      }
      .pagination-btn .btn {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      .cta-prev-medias{
        margin-right: 8px;
      }
      .cta-next-medias{
        margin-left: 8px;
      }
    }
    .thumbs-grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .thumb {
        width: 216px;
        padding: 0 16px 16px 0;        
        @media screen and (max-width: $screen-size-small) {
          width: 100%;
          padding-right: 0;
        }

        .avatar {
          width: 32px;
          height: 32px;
        }
      }
    }
  }    
}


#modal .popup {
  &.moteur-a-facettes {
    background: #eff1f5;
  }
  
  .sidebar {
    padding-left: 8px;
  }
}