.popup {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  overflow: hidden;
  animation: .25s popupinfade;
  justify-content: center;
  align-items: center;
  overscroll-behavior: contain;
  .popup-outsideclick{
    // background: rgba(0, 0, 0, 0.7);
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1000;
    position: absolute;
  }

  &.light {
    background: rgba(255, 255, 255, 0.6);
  }

  .popup-container {
    animation: .4s popupin;
    max-height: 100%;
    z-index: 1001;
    //overflow-y: scroll;
  }

  .close-popup {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 35px;
    padding: 8px;
    fill: #bfbfbf !important;
    z-index: 9999;
    cursor: pointer;
    transition: fill 0.3s;

    &:hover {
      fill: white !important;
    }


    svg {
      width: 18px;
    }
  }
}

@keyframes popupin {
  from {
    transform: scale(.5);
    opacity: 0.1;
  }
  30% {
    transform: scale(1.01);
  }
  60% {
    transform: scale(.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes popupinfade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: $screen-size-medium) {
  .popup {
    background: #FFF;
    flex-wrap: wrap;

    .close-popup {
      width: 100%;
      position: static;
      flex-shrink: 0;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      order: 2;
      border-top: 1px solid $color-grey-light;
      fill: darken($color-grey-light, 10) !important;
      font-style: normal;
      background: white;

      svg {
        width: 10px;
        display: none;
      }

      &::before {
        content: "<";
        font-size: 21px;
        font-weight: bold;
        color: white;
        background-color: $color-primary;
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        text-align: center;
        line-height: 30px;
        vertical-align: middle;
      }
      &::after {
        content: "Retour";
        display: inline-block;
        font-size: 16px;
        color: $color-primary;
        margin-left: 5px;
      }
    }

    .popup-container {
      overflow-y: scroll;
      max-height: 100%;
      height: calc(100% - 40px);
      width: 100%;
      order: 1;
    }
  }
}
