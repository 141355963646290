.likes {
  font-weight: 600;
  color: #000;
  border-radius: 50px;
  padding: 4px 8px;
  font-size: 12px;
  display: flex;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
            flex-direction: row;
            align-items: center;

  i,.like-icon {
    width: 12px;
    margin-right: 4px;
  }

  &.active {
    i,.like-icon {
      animation: animate-like .2s linear;
      color: $v3-color-like;
    }
  }
  &.noactive {
    cursor: default;
  }
}

.btn-likes {
  border: 2px solid $v3-color-like;
  background-color: #FFF;
  color: $color-red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 18px;
  border-radius: $border-radius;
  font-size: 14px !important;
  box-shadow: none;
  cursor: pointer;

  i {
    margin-right: 6px;
    width: 12px;
    fill: $color-red;
  }

  &.active {
    color: $color-grey-light;

    i {
      fill: $color-grey-light;
    }

    background-color: $v3-color-like;
  }
}

.likes-text {
  font-size: 12px;

  .dropdown-relatif {
    .icon-caret-up {
      z-index: 0;
    }

    .dropdown-content {
      li {
        display: flex;
        align-items: center;
        list-style-type: none;
        padding: 8px 16px;
        color: $color-text-default;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        border-bottom: 1px solid $color-grey-light;

        .avatar {
          margin-right: 8px;
          width: 32px;
          height: 32px;
          border-radius: $border-radius-rounded;
        }

        &:last-child {
          border-bottom: 0px;
        }

        &:hover {
          color: #FFF;
          background-color: $color-primary;
        }

        &.active {
          color: #FFF;
          background-color: $color-primary;
        }
      }
    }
  }
}

#like-popup {
  max-height: 350px;

  .modal-topline {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .icon-close {
      cursor: pointer;
    }
  }

  .like-list {
    width: 100%;

    .like-list-container {
      display: flex;
      flex-direction: column;
      height: calc(350px - 98px);
      overflow: auto;
      width: 100%;

      .btn{
        margin: 4px auto 0 auto;
        flex-shrink: 0;
      }

      a {
        text-decoration: none;
      }

      li {
        display: flex;
        align-items: center;
        list-style-type: none;
        padding: 8px;
        color: $color-text-default;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;

        .avatar {
          margin-right: 8px;
          width: 32px;
          height: 32px;
          border-radius: $border-radius-rounded;
        }

        &:hover {
          background-color: $color-grey-light;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .likes-text-container {
    #like-popup {
      max-height: 100vh;
      max-width: 100vw;
      overflow: hidden;
      width: 100vw;
      height: 100%;
      z-index: 10000;

      .container {
        width: 100%;
        height: 100%;

        .card {
          height: 100%;
          margin-top: 0;

          .topline {
            .icon-close {
              display: none;
            }
          }

          .like-list {
            height: 100%;

            .like-list-container {
              height: calc(100% - 49px);
            }
          }
        }
      }
    }
  }
}
