.loading-style {
  .loading-text {
    width: 100%;
    height: 10px;
    background-color: lighten($color-primary, 20);
    margin: 8px 0;
    animation: loading-background 2s linear infinite;

    &.end {
      width: 50%;
    }
  }

  &.timeline-loading {
    min-height: 400px;
    background-color: $color-grey-light;
    animation: loading-background-grey 2s linear infinite;
  }
}

@keyframes loading-background-grey {
  from {
    background-color: $color-grey-light;
  }
  50% {
    background-color: darken($color-grey-light, 5);
  }
  to {
    background-color: $color-grey-light;
  }
}

@keyframes loading-background-blue {
  from {
    background-color: lighten($color-primary, 35);
  }
  50% {
    background-color: lighten($color-primary, 25);
  }
  to {
    background-color: lighten($color-primary, 35);
  }
}
