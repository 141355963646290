.message-container {
  min-width: 0;
  max-width: 100%;
  position: unset;

  .upload-loading{
    margin: 0 4px;
  }

  .message-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 0;
    .flex-end{
      display: flex;
      justify-content: flex-end;
    }
    .message-input-container {
      border: 1px solid darken($color-grey-light, 10);
      border-radius: $border-radius-20;

      .message-input {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-grow: 1;

      }

      .tag-friend {
        position: absolute;
        left: 0;
        right: 0;

        .icon-caret-up {
          display: none;
        }

        .dropdown-content {
          width: 300px;
          height: 300px;
          max-height: 300px;
          overflow: auto;
          left: 0;
          right: auto;
          top: 18px;

          li {
            display: flex;
            align-items: center;
            list-style-type: none;
            padding: 8px 16px;
            color: #323232;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            border-bottom: 1px solid #E9E9E9;
            flex-shrink: 0;

            .avatar {
              width: 28px;
              height: 28px;
              margin-right: 8px;
            }

            &.active {
              color: #fff;
              background-color: $color-primary;
            }
          }
        }
      }

      .emoji-margin {
        .icon-smile {
          cursor: pointer;
          width: 20px;
          height: 20px;

          svg {
            fill: lighten($color-grey-medium, 30);
          }
        }

        .dropdown-children {
          height: 20px;
        }
      }

      .attachement-container {
        align-items: center;
        //border-top: 1px solid $color-grey-light;
        //margin: 4px;
        overflow: auto;
        white-space: nowrap;
        .overflow-container{
          display: flex;
          max-width: 100%;
        }

        .attachement-block {
          position: relative;
          margin: 4px;
          border-radius: $border-radius;
          overflow: hidden;
          height: 50px;
          width: 50px;
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid $color-grey-light;

          .spin-loader{
            position: absolute;
            flex-shrink: 0;
          }

          .img-preview{
            height: 100%;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          .layer {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 5;
            background-color: rgba($color-red, .5);
            display: flex;
            justify-content: center;
            align-items: center;
            visibility: hidden;
            border-radius: $border-radius;
            opacity: 0;
            transition: all ease-in-out .1s;

            span {
              width: 24px;
              height: 24px;
              background-color: #FFF;
              border-radius: $border-radius-rounded;
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                position: unset;
                top: unset;
                left: unset;
                fill: $color-red;
                width: 10px;
              }
            }
          }

          &:hover {
            .layer {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .send-btn {
    flex: 1 1 10%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn {
    align-items: center;
    cursor: pointer;
    border: 0;

    .icon-paper-plane {
      fill: $color-primary;
      width: 19px;
      height: 17px;
    }

    &.sendBtnIcon {
      padding: 8px 0 8px 8px;
      display: flex;
      //display: none;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .message-container {
    .emojiSelectPopover{
      position: fixed;
      bottom: 0;
      max-height: 200px;
      box-shadow: unset !important;
      .emojiSelectPopoverGroups{
        height: 150px;
      }
    }

    .message-input-wrapper {
      .message-input-container {
        .tag-friend {
          .dropdown-content {
            width: 100%;
          }
        }

        .attachement-container {
          .attachement-block {
            .layer {
              visibility: visible;
              opacity: 1;
              background-color: transparent;

              span {
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }
        }
      }
    }

    .btn {
      &.sendBtnIcon {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .message-container {
    .message-input-wrapper {
      .message-input-container {
        .emoji-margin {
          .icon-smile {
            margin: 0 8px !important;
          }
        }
      }
    }

    .send-btn {
      padding: 8px;
      border: 0;
    }
  }
}
