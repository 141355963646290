#signup{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 32px 10px;
    .signup-title{
        font-size: 1em;
        line-height: 1.3em;
        letter-spacing: -0.01em;
        max-width: 300px;
        font-weight: 500;
        margin-bottom: 16px;
        color: #000;
        text-align: center;
        margin-bottom: 32px;
    }
    .signup-subtitle{
        font-size: .9em;
        font-weight: 500;
        color: #444444;
        text-align: center;
        margin-bottom: 32px;
        line-height: 1.3em;
        letter-spacing: -0.01em;
        max-width: 300px;
    }
    form{
        width: 100%;
        max-width: 320px;
        .signup-check-cgu{
            padding: 8px 0;
            margin-bottom: 12px;
            font-size: .8em;
            input{
                margin-right: 8px;
            }
            a{
                color: #000;
                text-decoration: underline;
            }
        }
    }
    .signup-success{
        font-size: .9em;
        font-weight: 500;
        color: #000;
        text-align: center;
        line-height: 1.3em;
        letter-spacing: -0.01em;
        max-width: 300px;
        p{
            margin-bottom: 16px;
        }
    }
}