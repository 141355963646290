#moderatorVideo {
  .table {
    tr {
      td {
        vertical-align: middle;

        .avatar {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }

        .text {
          width: 100%;
          max-width: 200px;
          @include overflow-ellipsis();
        }
      }
    }
  }
}
