#maintenance{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    .maintenance-wrapper{
        max-width: 440px;
        width: 100%;
        padding: 8px;
        img{
            width: 100%;
            max-width: 140px;
        }
        h2{
            font-size: 1.4rem;
            line-height: 1.2em;
            font-weight: 600;
            margin: 0;
            margin-top: 24px;
        }
        p{
            font-size: 1rem;
            font-weight: 400;
            margin-top: 16px;
            line-height: 1.2em;
        }
        a{
            margin-top: 16px;
            display: inline-block;
            text-decoration: none;
            background: #000;
            color: #FFF;
            padding: 12px 16px;
            font-size: 1.1rem;
            font-weight: 400;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}