.toast-background{
  box-shadow: $box-shadow-light;
}

.toast-font{
  font-family: $font-primary;
  font-weight: 600;
  color: $color-primary;
  line-height: 1.3;
  font-size: 14px;
  &.toast-success{
    color: $color-green;
  }
  &.toast-error{
    color: $color-red;
  }
}

.toast-loader{
  &.toast-success{
    background: $color-green;
  }
  &.toast-error{
    background: $color-red;
  }
}