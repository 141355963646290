.modal-container {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  background-color: #FFF;
  min-width: 500px;
  max-height: 100vh;
  max-width: 100vw;

  .modal-topline {
    display: inline;
    align-items: center;
    border-bottom: 1px solid $color-grey-light;
    padding: 16px;
    font-size: 16px;
    @extend .break-long-text;
  }

  .modal-body {
    //display: flex;
    //flex-wrap: wrap;
    flex-grow: 1;
    padding: 16px;
    overflow: auto;
  }

  .modal-baseline {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid $color-grey-light;
    margin-top: auto;

    .btn {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .modal-container {
    min-width: 100%;
    height: 100%;

    .btn {
      flex-grow: 1;
    }
  }
}