#mediaEdit {
  max-width: 700px;
  width: 100vw;
  max-height: 100vh;
  overflow: auto;

  .mediaEdit-container {
    background-color: #FFF;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;

    .select-cover{
      display: flex;
      justify-content: center;
    }

    .media-tags{
      display: flex;
      justify-content: center;
    }

    .thumb-container {
      width: 200px;
      height: 200px;
      margin: 16px auto;
      padding: 0 16px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: $border-radius;
      position: relative;
      background-color: $color-grey-light;
    }

    .custom-textarea{
      height: 195px;
    }
    .temperature-information {
      font-size: 12px;
      color: $color-text-default;
      line-height: 1.3333;
      min-height: 60px;
    }

  }
}

@media screen and (max-width: $screen-size-medium) {
  #mediaEdit {
    height: 100%;
    max-width: unset;
  }
}

@media screen and (max-width: $screen-size-small) {
  #mediaEdit {
    .mediaEdit-container {
      flex-wrap: nowrap;
    }

    .modal-baseline {
      .btn {
        &.grey {
          display: none;
        }
      }
    }
  }
}