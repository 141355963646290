@import '../../../../../sass/variables';

.profile-gender-info{
    // background: red;
    font-size: .7em;
    color: #FFF;
    background: #666666;
    border-radius: 100px;
    font-weight: 600;
    padding: 2px;
    padding-left: 10px;
    display: flex;
    min-height: 24px;
    justify-content: center;
    align-items: center;
    .profile-gender-info-label{
        margin-right: 8px;
    }
    .profile-gender-info-item{
        margin-left: 1px;
        background: rgba(255, 255, 255, 0.3);
        color: #FFF;
        min-height: 20px;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 1.1em;
        padding: 4px 8px;
        font-size: .9em;
        &:first-child{
            margin-left: -2px;
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
        }
        &:last-child{
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
        }
    }
}