.tag-options {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  width: 100vw;
  background-color: #FFF;
  border-radius: $border-radius;

  .modal-body{
    display: flex;
    flex-wrap: wrap;
  }

  .label {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 0;
    margin-bottom: 0;
  }

  .custom-select {
    margin: 4px 8px;
    width: calc(50% - 16px);
  }

  input {
    margin-bottom: 8px;

    &.bottom-bar-style {
      flex-grow: 1;
    }
  }

  .add-tag-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .primary-reverse{
      margin: 4px 0;
    }
  }

  .btn-group {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    padding-top: 16px;
  }
}
