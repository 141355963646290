.badge {
  color: #FFF;
  background-color: $color-primary;
  border-radius: 25px;
  padding: 4px 6px;
  font-size: 11px;
  font-weight: 600;
  user-select: none;
  .svg-inline--fa{
    margin-right: 2px;
    margin-right: 2px;
  }

  &.dark {
    background-color: white;
    color: $color-dark-light;
  }

  &.red {
    color: $color-red;
    background-color: #FFF;
  }

  &.red-reverse {
    color: #FFF;
    background-color: $color-red;
  }

  &.light-reverse {
    color: #000000;
    background: rgba(125, 125, 125, 0.07);
    border: 1px solid rgba(125, 125, 125, 0.1);
  }

  &.primary {
    color: $color-primary;
    background-color: #FFF;
    border: 1px solid $color-primary;
  }

  &.primary-reverse {
    color: #FFF;
    background-color: $color-primary;
  }

  &.poll, &.pinned, &.closed {
    background-color: $color-green;
    color: #FFF;
    font-weight: 700;
    font-size: 12px;
    padding: 2px 4px;
    margin-right: 8px;
    border-radius: $border-radius;
  }

  &.pinned {
    background-color: lighten($color-green, 5%);
  }

  &.closed {
    background-color: $color-red;
  }
}
