$color-main: #FF8AB5;
$color-secondary: #FFBEB8;
$color-third: #FF5E3F;
$color-primary-pink: #ffaaa4; //new pink
$color-primary: #2276f4;
$color-purple: #9b59b6;
$color-redpink: #ee5379;
$color-dark-red: #290000;
$color-green: #28a745;
$color-light-pink: #f9ddf9;
$color-dark-light: #4A4A4A;
$color-grey-light: #E9E9E9;
$color-grey-medium: #4D4D4D;
$color-grey-darken: #434343;
$color-text-default: #333333;
$color-text-medium-2: #6F6F6F;
$color-red: #fb4d4d;
$color-text-grey-medium: #808080;
$color-action: #4DDC86;
$color-orange: #ffb142;
$upload-grey: #CFCFCF;


// $main-background-color: #f3f5f8;
$main-background-color: #FFF;

/***** Gender Colors *****/

$color-gender-man: #3498DB;
$color-gender-woman: #F8A6F6;
$color-gender-couple: #9B59B6;
$color-gender-couple-gay: #D35400;
$color-gender-couple-lesbian: #2ECC71;
$color-gender-trav: #F1C40F;

/***** Screen Size *****/

$screen-size-desktop: 1200px;
$screen-size-medium: 1024px;
$screen-size-tablette: 768px;
$screen-size-between-small-tablette: 720px;
$screen-size-small: 720px;
$screen-size-small2: 450px;
$screen-size-min: 320px;

/***** Fonts *****/
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');
$font-primary: 'Poppins', sans-serif;
$font-for-readability: 'Open Sans', sans-serif;
//Helvetica

/***** Styles ****/

$gradient: linear-gradient(-134deg, #3023AE 0%, #C96DD8 100%);
$border-radius: 6px;
$border-radius-20: 20px;
$border-radius-rounded: 100%;
// $box-shadow : 0px 2px 4px 0px rgba(0, 0, 0, 0.21);
$box-shadow: 0 2px 6px rgba(0, 0, 0, .08);
$box-shadow-1: 0 3px 9px rgba(0, 0, 0, .08);
$box-shadow-2: 0 4px 12px rgba(0, 0, 0, .16);
$box-shadow-light: 0px 1px 4px 0px rgba(0, 0, 0, 0.21);

/***** Size *****/

$size-sidebar: 276px;

// comment text expander

$line-height: 16px;
$comment-nb-line-allowed: 5;
$article-nb-line-allowed: 20;

/***** V3 colors *****/
// $v3-color-voissa-pink: #F7BCB9;
$v3-color-primary: #ff5757;
$v3-color-secondary: #ff59b7;
$v3-color-gradient: linear-gradient(135deg, $v3-color-primary 0%,$v3-color-secondary 100%);
$v3-color-gradient-accent: linear-gradient(145deg, darken($v3-color-primary, 4%) 0%,lighten($v3-color-secondary, 8%) 80%);
$v3-color-bg-light-grey: #f5f5f5;
$v3-color-bg-light-grey-hover: #e9e9e9;
$v3-color-bg-black-hover: lighten(#000, 20%);
$v3-color-certified: #0095ff;
$v3-color-like: #ff0048;
$v3-color-link : #2276f4;
$v3-color-success : #00d67d;
$v3-default-border-radius: 8px;
$v3-color-favorite: #ffd900;
$v3-navbar-height: 52px;
$v3-footer-height: 116px;
$v3-padding-drawer: 10px;
$v3-navbar-z-index: 100;
$v3-shadow : 0 1px 1px hsl(0deg 0% 0% / 0.075),
0 2px 2px hsl(0deg 0% 0% / 0.075),
0 4px 4px hsl(0deg 0% 0% / 0.075),
0 8px 8px hsl(0deg 0% 0% / 0.075),
0 16px 16px hsl(0deg 0% 0% / 0.075)
;

@mixin v3TextGradient {
    background-image: $v3-color-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.text-gradient {
    @include v3TextGradient;
}

.text-primary {
    color: $v3-color-primary;
}

@mixin placeholder {
    &.placeholder {
      @content
    }
    &:-moz-placeholder {
      @content
    }
    &::-moz-placeholder {
      @content
    }
    &::-webkit-input-placeholder {
      @content
    }
    &:-ms-input-placeholder {
      @content
    }
  }
  