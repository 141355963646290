.join-attachement {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      margin-bottom: 8px;
    }

    .upload {
      position: relative;
      border: 1px dashed $color-primary;
      width: 100%;
      min-height: 100px;
      padding: 16px;
      color: $color-primary;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 2;
        cursor: pointer;
      }

      h4 {
        color: $color-text-default;
        margin-bottom: 8px;
      }

      div {
        span {
          display: block;
        }
      }

      .row {
        .img-container {
          margin-right: 16px;

          img {
            max-width: 100px !important;
            max-height: 100px !important;
          }
        }
      }
    }
  }

  .row {
    margin: 16px 0;
    align-items: center;

    span {
      font-size: 13px;
      color: $color-text-medium-2;
    }

    .primary {
      margin-right: 8px;
    }

    .reset-selection {
      color: $color-red;
      padding: 8px;
      border-radius: $border-radius;
      cursor: pointer;
      margin-left: 16px;
    }

    .base-report {
      color: $color-red;
      margin-left: 8px;
      font-size: 13px;
      cursor: pointer;
    }

    &.no-use {
      user-select: none;

      .primary, .reset-selection {
        color: $color-text-medium-2;
        background-color: $color-grey-light;
        cursor: not-allowed;
      }
    }
  }

  .uploaded-files {
    display: flex;
    flex-direction: column;

    .attachement-file {
      margin: 16px 0;
      align-items: center;

      .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        max-height: 50px;
        height: 50px;
        overflow: hidden;

        .fa-circle-o-notch {
          margin: 0;
          padding: 0;
        }

        img {
          max-width: 100% !important;
          max-height: 100% !important;
          width: auto;
          height: auto;
        }
      }

      .infos {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        padding-left: 16px;
        justify-content: center;

        span {
          &:first-child {
            color: $color-text-default;
            font-weight: 700;
            word-break: break-all;
          }
        }
      }

      .settings-btn-group {
        margin-left: auto;

        span {
          cursor: pointer;

          &:first-child {
            color: $color-primary;
          }

          &:nth-child(2) {
            color: $color-red;
            margin-left: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .join-attachement {
    margin-top: 16px;

    .uploaded-files {
      .attachement-file {
        flex-direction: column;

        .infos {
          padding-left: 0;
          margin-left: 0;
          margin: 8px 0;
          width: 100%;
          align-items: center;
        }

        .settings-btn-group {
          margin-left: 0;
          justify-content: center;
        }
      }
    }

    .row {
      justify-content: center;
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .join-attachement {
    .row {
      flex-direction: column;

      .btn-rounded-corner, .reset-selection {
        margin: 4px 0;
        width: 100%;
      }
    }
  }
}
