#confirm-email {

  .container {
    max-width: 700px;
    width: 100%;

    .card {
      padding: 16px;
      overflow: hidden;

      .main-wrapper {
        h2 {
          font-size: 36px;
          letter-spacing: .02em;
          font-weight: 700;
          color: #333;
          padding: 16px;
          margin-bottom: 8px;
          border-radius: 4px 4px 0 0;
          text-align: center;
        }



        .text {
          margin-top: 16px;
          p {
            font-size: 13px;
            padding-bottom: 8px;
            line-height: 1.3;
            color: $color-text-medium-2;

            &:last-child {
              padding-bottom: 0;
            }

            &.color-success{
              color: $color-green;
            }

            &.color-danger{
              color: $color-red;
            }
          }
        }
      }
    }
  }
}
