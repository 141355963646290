.import-media {
  background-color: #FFF;
  border-radius: $border-radius;
  display: flex;
  height: 90vh;
  width: 90vw;
  position: relative;
  overflow: hidden;

  .import-media-nav{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: #FFF;
    border-bottom: 1px solid $color-grey-light;
    padding: 8px ;
    .sidebar-menu {
      display: inline-flex;
      flex: 1 1 calc(70% - 16px);
      margin: 8px;
    }
    .custom-text-input {
      display: inline-flex;
      flex: 1 1 calc(30% - 16px);
      margin: auto 8px;
    }
    .back-btn{
      margin: 8px 0 8px auto;
    }
  }

  .modal-body{
    position: relative;
    padding: 0;
    .grid{
      display: none;
      flex-wrap: wrap;
      width: 100%;
      .list{
        display: flex;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid $color-grey-light;
        flex: 1 1 100%;
        .custom-checkbox-input{
          margin-right: 8px;
        }
        .folder, .article{
          flex-shrink: 0;
          .icon-folder, .icon-article{
            width: 22px;
          }
        }
        .thumb{
          position: relative;
          height: 80px;
          width: 80px;
          border: 1px solid $color-grey-light;
          overflow: hidden;
          border-radius: $border-radius;
          flex-shrink: 0;
          img{
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: auto;
          }
          .btn-play {
            position: absolute;
            bottom: 4px;
            right: 4px;
            i {
              height: 32px;
              width: 32px;
            }
          }
        }
        .title{
          padding: 0 16px;
          font-weight: 700;
          cursor: pointer;
          @extend .break-long-text;
        }
        .options{
          display: flex;
          align-items: center;
          margin-left: auto;
          .share, .enter{
            cursor: pointer;
            padding: 0 16px;
            color: $color-primary;
          }
          .delete{
            cursor: pointer;
            padding: 0 16px;
            color: $color-red;
          }
        }
        &.media{
          flex: 0 0 50%;
          padding: 16px;
          border-bottom: 0px;
          border-top: 1px solid $color-grey-light;
          &:first-child, &:nth-child(2){
            border-top: 0px;
          }
        }
        &:last-child{
          border-bottom: 0px;
        }
      }
      &.active{
        display: flex;
      }
    }

    .pdf-file {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-red;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 700;
      border: 2px solid $color-red;
      height: 100%;
      width: 100%;
      user-select: none;
    }

    .article-file {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-text-medium-2;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      border: 2px solid $color-text-medium-2;
      height: 100%;
      width: 100%;
      user-select: none;
    }

    .media-container {
      height: 100%;
      width: 100%;
      .photo-grid {
        flex-wrap: wrap;
        display: none;
        overflow: scroll;
        height: calc(100% - 136px);
        position: relative;
        justify-content: flex-start;
        align-items: flex-start;

        .photo-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          width: calc((100% / 10) - 14.5px);
          margin: 8px 16px 8px 0px;
          position: relative;
          padding: 8px;
          cursor: pointer;
          min-width: 120px;
          max-width: 150px;

          .photo-thumb {
            height: 80px;
            width: 80px;
            position: relative;
            border-radius: $border-radius;
            overflow: hidden;

            .btn-play {
              position: absolute;
              height: 32px;
              width: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              bottom: 4px;
              right: 4px;
              background: #FFF;
              border-radius: 50px;

              i {
                fill: $color-primary;
                width: 16px;
                margin-top: 3px;
                margin-right: -2px;
              }
            }
          }

          .title {
            margin-top: 8px;
            font-size: 12px;
            color: $color-text-medium-2;
            @extend .break-long-text;
          }

          &:first-child {
            margin-left: 0;
          }

          &:nth-child(10n) {
            margin-right: 0;
          }

          &:nth-child(11n) {
            margin-left: 0;
          }

          &.active {
            background-color: lighten($color-primary, 40%);
          }
        }

        &.active {
          display: flex;
        }
      }

      .album-grid {
        flex-wrap: wrap;
        display: none;
        overflow: scroll;
        height: calc(100% - 136px);
        position: relative;
        justify-content: flex-start;
        align-items: flex-start;

        .btn-play {
          display: none;
        }

        .album-block {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          position: relative;
          width: calc((100% / 7) - 28px);
          margin: 16px 32px 16px 0;
          padding: 8px;

          .album-thumb {
            position: relative;
            height: 96px;
            width: 96px;
            margin-bottom: 16px;
            user-select: none;
          }

          .title {
            font-size: 12px;
            color: $color-text-medium-2;
          }

          .thumb {
            position: absolute;
            box-shadow: $box-shadow-light;
            border-radius: $border-radius;
            overflow: hidden;

            &:nth-child(1) {
              z-index: 3;
              left: 0;
              top: 0;
            }

            &:nth-child(2) {
              z-index: 2;
              left: 8px;
              top: 8px;
              opacity: 0.8;
            }

            &:nth-child(3) {
              z-index: 1;
              left: 16px;
              top: 16px;
              opacity: 0.6;
            }
          }

          &:nth-child(7n) {
            margin-right: 0;
          }

          &.active {
            background-color: lighten($color-primary, 40%);
          }
        }

        &.active {
          display: flex;
        }
      }

      .attachment-blog-grid {
        display: none;
        position: relative;
        justify-content: flex-start;
        align-items: flex-start;

        .blog-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(100% / 10 - 16px);
          margin: 8px;
          position: relative;
          cursor: pointer;
          min-width: 80px;
          flex-grow: 1;
          max-width: calc(100% / 10 - 16px);

          .blog-thumb {
            height: 80px;
            width: 80px;
            position: relative;
            border-radius: $border-radius;
            overflow: hidden;

            .btn-play {
              position: absolute;
              height: 32px;
              width: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              bottom: 4px;
              right: 4px;
              background: #FFF;
              border-radius: 50px;

              i {
                color: $color-primary;
              }
            }
          }

          .title {
            margin-top: 8px;
            font-size: 12px;
            color: $color-text-medium-2;
          }
        }

        &.active {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }

  .modal-baseline{
    .selection-count{
      font-size: 14px;
      color: $color-text-grey-medium;

    }
  }

  .active-quick-check {
    width: 100%;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.25);;
    z-index: 3;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    display: flex;
    align-items: center;
    transform: translateY(80px);
    transition: transform ease-in-out .08s;

    .blog-block {
      margin: 0 4px;

      .blog-thumb {
        padding: 4px 8px;
        border: 1px solid $color-text-default;
        margin-bottom: 4px;
        border-radius: 12px;
      }
    }

    .import-media-btn-group {
      display: flex;
      flex-direction: column;
      padding: 0 16px 0 8px;
      width: auto;

      .btn-rounded-corner {
        margin: 4px 0;

        &:first-child {
          color: $color-red;
        }
      }
    }

    .hide {
      margin: 0 auto;
      background-color: #FFF;
      position: absolute;
      left: calc(50% - 22px);
      padding: 2px 16px;
      min-height: 16px;
      cursor: pointer;
      border-radius: $border-radius $border-radius 0 0;

      i {
        fill: $color-primary;
      }

      &.down {
        top: -28px;
      }

      &.up {
        top: -8px;
      }
    }

    .wrapper {
      display: flex;
      width: 100%;
      margin: 0 8px;
      padding: 8px 0;
      overflow: auto;
      user-select: none;

      .remove-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;
        background-color: rgba($color-red, .5);
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        border-radius: $border-radius;
        opacity: 0;
        transition: all ease-in-out .1s;

        span {
          width: 24px;
          height: 24px;
          background-color: #FFF;
          border-radius: $border-radius-rounded;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            position: unset;
            top: unset;
            left: unset;
            fill: $color-red;
            width: 10px;
          }
        }
      }

      .photo-wrapper {
        margin-right: 8px;
        position: relative;
        min-height: calc(80px - 16px);
        min-width: 64px;
        overflow: hidden;
        cursor: pointer;

        .btn-play {
          position: absolute;
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 4px;
          right: 4px;
          background: #FFF;
          border-radius: 50px;

          i {
            color: $color-primary;
            font-size: 11px;
          }
        }

        img {
          height: calc(80px - 16px);
          border-radius: $border-radius;
        }

        &:hover {
          .remove-layer {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .album-wrapper {
        margin-right: 8px;
        position: relative;
        min-height: calc(80px - 16px);
        min-width: 64px;
        overflow: hidden;
        cursor: pointer;

        .thumb {
          position: absolute;
          box-shadow: $box-shadow-light;
          border-radius: $border-radius;
          overflow: hidden;

          &:nth-child(1) {
            z-index: 3;
            left: 0;
            top: 0;
          }

          &:nth-child(2) {
            z-index: 2;
            left: 8px;
            top: 8px;
            opacity: 0.8;
          }

          &:nth-child(3) {
            z-index: 1;
            left: 16px;
            top: 16px;
            opacity: 0.6;
          }

          .btn-play {
            display: none;
          }

          img {
            height: calc(60px - 16px);
            border-radius: $border-radius;
          }
        }

        &:hover {
          .remove-layer {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .attachement-wrapper {
        margin-right: 8px;
        position: relative;
        min-height: calc(80px - 16px);
        min-width: 64px;
        overflow: hidden;
        cursor: pointer;

        .btn-play {
          position: absolute;
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 4px;
          right: 4px;
          background: #FFF;
          border-radius: 50px;

          i {
            color: $color-primary;
            font-size: 11px;
          }
        }

        img {
          height: calc(80px - 16px);
          border-radius: $border-radius;
        }

        &:hover {
          .remove-layer {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    &.active {
      transform: translateY(0px);
    }
  }
  &.select-profile-media{
    width: 100%;
    height: unset;
    overflow: unset;
    .modal-body {
      .grid {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .import-media {
    height: 100%;
    width: 100vw;
    border-radius: 0;

    .container {
      width: 100%;
      max-width: 100vw;

      h3 {
        padding: 8px;
        margin: 0;

        span {
          display: none;
        }
      }

      .navbar {
        width: 100%;
        padding: 8px;
        overflow: auto;

        li {
          flex-shrink: 0;

          span {
            flex-shrink: 0;
          }
        }
      }

      input[type="text"] {
        margin: 0 8px;
        width: calc(100% - 16px) !important;
      }

      .media-container {
        .photo-grid {
          height: calc(100% - 215px);

          .photo-block {
            margin: 0;
          }
        }

        .album-grid {
          height: calc(100% - 215px);
          justify-content: space-between;

          .album-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            width: calc((100% / 3) - 16px);
            position: relative;
            padding: 8px;
            margin: 8px;
            cursor: pointer;
            min-width: 80px;
            max-width: 150px;
          }
        }

        .attachment-blog-grid {
          .blog-block {
            width: calc(100% / 6 - 16px);
            max-width: calc(100% / 6 - 16px);
          }
        }
      }
    }

    .active-quick-check {
      .hide {
        &.up {
          bottom: 90px;
        }

        &.down {
          bottom: 90px;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .import-media {
    .import-media-nav{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      flex-shrink: 0;
      .back-btn{
        flex-shrink: 0;
        width: 100%;
      }
    }
    .modal-body {
      .grid{
        .enter{
          display: none;
        }
        .list{
          &.media{
            flex: 1 0 100%;
          }
          .thumb{
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .container {
      .media-container {
        .attachment-blog-grid {
          .blog-block {
            width: calc(100% / 3 - 16px);
            max-width: calc(100% / 3 - 16px);
          }
        }
      }
    }
  }
}
