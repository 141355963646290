.profile-side-friends {
  .list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .friends {
    display: flex;
    border: 0;
    padding: 0;
    //width: 80px;
    width: calc(100% / 3 - 4px);
    margin: 2px;
    height: 80px;

    a {
      display: flex;
      height: 100%;
      width: 100%;

      .avatar {
        display: flex;
        height: 100%;
        width: 100%;
        border-radius: 0;
        border: 0;
        overflow: hidden;

        .avatar-container {
          border: 0px solid transparent;
          border-radius: 0;
          position: relative;
          width: 100%;
          height: 100%;
          overflow: visible;

          img {
            border: 0;
            border-radius: 0;
          }
        }
      }
    }
  }

  .btn {
    width: 100%;
    margin-top: 4px;
  }
}