#chat-webcam{
  .iframe-chat{
    height : calc(100vh - 102px);
    width : 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  .chat-webcam-message{
    padding: 8px;
    line-height: 1.3em;
  }
}

@media screen and (max-width: $screen-size-medium) {
  #chat-webcam{
    .iframe-chat{
      height : calc(100vh - 56px);
    }
  }
}