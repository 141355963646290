@import 'src/sass/_variables.scss';

.certification-modal{
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    &-icon{
        width: 130px;
        margin-bottom: 20px;
    }
    &-title{
        font-size: 1.55em;
        font-weight: 600;
        line-height: 1.3em;
        margin-bottom: 8px;
        text-align: center;
        span{
            color : $v3-color-primary;
        }
    }   
    &-description{
        margin-bottom: 18px;
        line-height: 1.3em;
        font-weight: 500;
        font-size: .8em;
        text-align: center;
    }
    &-action{
        & > *{
            margin-bottom: 8px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &-list{
        // width: 100%;
        // background: red;
        margin-bottom: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-item{
            display: inline-flex;
            align-items: center;
            // width: 100%;
            margin: 8px 0;
            line-height: 1.3em;
            font-weight: 500;
            font-size: .8em;
            &-icon{
                width: 24px;
                height: 24px;
                flex-shrink: 0;
                margin-right: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-size: 12px;
                background-color: $v3-color-success;
                border-radius: 50%;
            }
        }
    }
}