// MARGIN

.m-0 {
  margin: 0 !important;
}

.m-4 {
  margin: 4px;
}

.m-8 {
  margin: 8px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mx-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-auto {
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-32 {
  margin-right: 32px;
}
