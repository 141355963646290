.autocompleted-tags {
  display: flex;
  //align-items: center;
  //border-bottom: 1px solid $color-grey-light;
  //min-height: 55px;
  //height: 55px;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  .infinite-loading-container{
    width: 100%;
  }

  input {
    height: 54px;
    border: 0;
    padding: 12px 8px 8px 8px;
    flex-grow: 1;
  }
}

.tag-box {
  .dropdown-relatif {
    .dropdown-content {
      top: -12px;
      left: 10px;
    }
  }

  .icon-caret-up {
    display: none;
  }

  .tags {
    &.active {
      background-color: $color-primary;
      color: #FFF;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .autocompleted-tags {

  }
  .tags-box {
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;
    align-items: flex-start;


    .tag-box {
      width: 100%;
      overflow: auto;
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .dropdown-relatif {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .dropdown-content {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          justify-content: unset;
          position: absolute;
          max-height: unset !important;

          li {
            flex-shrink: 0;
          }

          .close-dropdown {
            position: sticky;
            bottom: 0;
          }
        }
      }
    }
  }
}