.views {
  position: absolute;
  top: 12px;
  left: 12px;
  font-weight: bold;
  color: #323232;
  background: rgba(233, 233, 233, 0.85);
  border-radius: 50px;
  padding: 4px 8px;
  font-size: 12px;
  display: flex;
  font-weight: 400;

  i {
    fill: $color-text-grey-medium;
    width: 12px;
    margin-right: 8px;
  }
}

.views-text {
  font-size: 12px;
  color: $color-text-grey-medium;
  display: flex;
  justify-content: center;
  align-items: center;
}
