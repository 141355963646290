#shift {
  width: 100%;
  .container {
    .card {
      padding: 8px;
      margin: 0 auto;

      select {
        width: 100%;
        margin: 16px 0;
      }

      .row {
        padding: 8px 0;
      }
    }
  }
}
