#checkRequest{
  max-width: 800px;
  width: 100vw;
  .modal-topline{
    display: flex;
    justify-content: space-between;
    h3{
      font-size: 18px;
    }
    .icon-close{
      cursor: pointer;
    }
  }
  .answers-container{
    margin: 0 8px;
    overflow: auto;
    height: 100%;
    max-height: 500px;
    .answers-container-inner{
      display: flex;
      flex-direction: column;
      .block{
        margin: 8px 0;
        flex-shrink: 0;
        color: $color-text-default;
        padding: 8px 0;
        background-color: #FFF;
        flex-shrink: 0;
        .question-container{
          display: flex;
          margin: 8px 0 0 0;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          border-bottom: 1px solid $color-grey-light;
          padding-bottom: 8px;
        }
        .answer-container{
          margin: 8px 0 8px 0;
          font-size: 16px;
          line-height: 1.6rem;
          padding: 0 0 8px 0;
          opacity: .8;
          font-family: $font-for-readability;
          @extend .break-long-text;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  #checkRequest{
    height: 100%;
    .container{
      max-width: 100vw;
      max-height: 100%;
      width: 100vw;
      height: 100%;
      .card{
        margin-top: 0;
        max-height: 100%;
        height: 100%;
        .answers-container{
          height: 100%;
          max-height: calc(100% - 110px);
          min-height: 0;
        }
        .btn-group{
          padding: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #checkRequest{
    .container{
      .card{
        .answers-container{
          margin: 0;
          .answers-container-inner{
            .block{
              margin: 0;
              padding: 16px;
            }
          }
        }
      }
    }
  }
}