.toast{
  .content{
    display: flex;
    &.info{
      color: #0c5460;
      background-color: #d1ecf1;
      border-color: #bee5eb;
    }

    &.success{
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
    }
    &.warning{
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
    }
    &.danger{
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
    }

    .text{
      width: 98%;
      display: flex;
      padding: 4px;
    }

    .close {
      width: 2%;
      display: flex;
      padding: 4px;
      justify-content: center;
    }

  }

}