#settings {
  .profil-meet-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .custom-select {
      width: 100%;
      margin: 8px 0;
    }

    .custom-checkbox-input, .custom-radio-input {
      width: calc(100% / 3);
      margin: 8px 0;

      input[type="radio"] {
        margin-right: 8px;
      }
    }

    .custom-radio-input {
      width: calc(100% / 4);
    }
  }
  .no-content{
    margin-bottom: 8px;
    .validate-toggle-displayInMeet{
      position: relative;
      span{
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transition: all .2s linear;
        transform: translateY(-20px);
        left: 0;
        width: 100%;
      }
    }
    .toggle{
      &.isToggled{
        background-color: darken($color-green, 15);
      }
    }
    &.active{
      color: #FFF;
      background-color: $color-green;
      border: 1px solid $color-green;
      .validate-toggle-displayInMeet{
        span{
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
  }

  .flex-table {
    .tag-list {
      margin-top: 4px;
    }
  }
}