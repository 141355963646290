.card-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  background: #FFF;
  border-bottom: 1px dashed $color-grey-light;

  a, a:focus {
    color: #000;
    text-decoration: none;
  }

  .wrapper-left {
    display: flex;
    align-items: flex-start;
    flex: 1;

    .icon {
      font-size: 28px;
      padding-right: 16px;
      color: #9E9E9E;

      .icon-svg {
        width: 28px;
        fill: #9E9E9E;
      }
    }

    .wrapper-card-category {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      .wrapper-title {
        max-width: 550px;
        line-height: 1.2;

        .title {
          font-size: 18px;
          margin-bottom: 6px;

          a {
            color: #000;
            font-family: $font-for-readability;
          }
        }
        h3{
          font-weight: normal;
          a{
            font-size: 18px;
          }
        }

        .sub-forums {
          display: flex;
          flex-wrap: wrap;
          margin: 8px 16px;
          margin-right: 0;
          position: relative;

          li {
            padding: 4px;
            font-size: 13px;

            a {
              color: #000;
            }
          }

          &::before {
            content: "\230A";
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: -3px;
            left: -8px;
            color: lighten($color-grey-medium, 40%);
          }
        }

        .description {
          font-size: 13px;
          color: $color-text-medium-2;
          font-family: $font-for-readability;
        }
      }

      .wrapper-infos {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        line-height: 1.2;

        .subjects, .answers, .views-text {
          font-size: 13px;
          margin: 2px 0;
          color: $color-text-medium-2;

          i {
            fill: $color-text-medium-2;
            margin-left: 4px;
          }
        }
      }
    }
  }

  .last-post {
    display: flex;
    flex-direction: row;
    width: 250px;
    padding-left: 16px;
    align-items: flex-start;
    overflow: hidden;

    .avatar {
      width: 42px;
      height: 42px;
    }

    .wrapper-infos-last {
      padding-left: 16px;
      line-height: 1.2;

      .title-last-post {
        color: $color-primary;
        font-size: 14px;
        margin-bottom: 8px;

        a, a:focus {
          text-decoration: none;
          color: $color-primary;
          font-weight: 600;
        }
      }

      .username-last-post {
        color: $color-text-medium-2;
        font-size: 12px;
        margin: 2px 0;

        span {
          color: $color-primary;
        }
      }
    }
  }

  &:last-child {
    border-bottom: 1px solid transparent;
  }
}

@media screen and (max-width: $screen-size-medium) {
  .card-category {
    .wrapper-left {
      margin-bottom: 8px;

      .wrapper-card-category {
        .wrapper-title {
          .sub-forums {
            display: none;
          }
        }

        .wrapper-infos {
          display: none;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 8px;
        }
      }
    }

    .last-post {
      align-self: flex-start;
      padding-left: 0;
      align-items: flex-start;
      padding: 8px;

      .avatar {
        width: 32px;
        height: 32px;
      }

      .wrapper-infos-last {
        padding-left: 8px;
      }

      .text {
        &.small {
          display: none;
        }
      }
    }
  }
}


@media screen and (max-width: $screen-size-small) {
  .card-category {
    align-items: flex-start;
    padding: 16px 8px;

    .wrapper-left {
      margin-right: 8px;
      width: 50%;
      align-items: flex-start;

      .wrapper-card-category {
        flex-direction: column;
        align-items: flex-start;

        .wrapper-title {
          .description {
            display: none;
          }
        }
      }
    }

    .last-post {
      width: 50%;
      padding-top: 0;
    }
  }
}
