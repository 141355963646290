.media-datas-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  transition: all .2s linear;
  overflow: auto;

  .popup {
    z-index: 10000;
  }

  .informations, .tag-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    padding: 0 8px;

    .category-title {
      display: flex;
      color: $color-text-default;
      font-size: 14px;
      margin-bottom: 12px;
      flex-shrink: 0;

      i {
        fill: $upload-grey;
        margin-right: 6px;
      }

      .more-tag {
        color: $color-primary;
        margin-left: auto;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .custom-textarea{
      margin: 4px 0;
      height: 180px;
      resize: vertical;
      border: 1px solid $color-grey-light;
      border-radius: $border-radius;
      padding: 8px;
      overflow: auto;
      .DraftEditor-root{
        height: 100%;
      }
    }

    .error-text {
      color: $color-red;
      font-size: 12px;
      line-height: 1.3333;
      margin: 4px 0;
    }

    .filter {
      border-color: $upload-grey;
      flex-shrink: 0;

      li {
        &.active {
          background-color: $upload-grey;
          color: $color-text-default;
        }
      }

      &.error {
        border-color: $color-red;
      }
    }

    .no-content {
      height: 85px;
    }

    .tag-list {
      max-height: 85px;
      overflow: auto;
      margin: 4px 0;
      align-items: flex-start;
      border: 1px solid $color-grey-light;
      border-radius: $border-radius;
      padding: 8px;
    }

    .temperature-information {
      font-size: 12px;
      color: $color-text-default;
      line-height: 1.3333;
      min-height: 45px;
    }

    .h-45 {
      min-height: 45px;
    }

    .filter, .temperature-information, .no-content, input, textarea, .error {
      margin: 4px 0;
    }
  }

  &.hidden {
    opacity: 0;
    height: 0;
    visibility: hidden;
    padding: 0;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    //height: 233px;
    padding: 12px 8px;
  }
}

@media screen and (max-width: $screen-size-small) {
  .media-datas-wrapper {
    .tag-wrapper {
      margin-top: 16px;
    }

    &.active {
      height: unset;
    }
  }
}