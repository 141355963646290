@import '../../../sass/variables';

.thumbnails-grid {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(5, 1fr);
    padding: 4px;
    width: 100%;
    &> * {
        width: 100%;
        height: auto;
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.thumbnails-grid-no-content-wrapper{
    position: relative;
    padding: 4px;
    width: 100%;
    padding-top: calc(100% / 5);
    border-radius: $v3-default-border-radius;
    .thumbnails-grid-no-content{
        position: absolute;
        display: flex;
        font-weight: 500;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        background: $v3-color-bg-light-grey;
        border-radius: 12px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}