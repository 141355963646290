.drawer-location{
    padding: 16px;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    p{
        font-size: 1.1em;
        margin-bottom: 24px;
        font-weight: 500;
        margin-top: 16px;
        line-height: 1.4em;
        text-align: center;
    }
}