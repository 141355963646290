#warning{
  width: 100vw;
  max-width: 800px;
  max-height: calc(100vh - 32px);
  overflow: auto;
  background-color: #FFF;
  .modal-topline{
    line-height: 1.1;
  }
  .warning-title{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    align-items: center;
    .btn{
      flex-grow:initial;
    }
  }
  .warning-list{
    ul{
      display: flex;
      align-items: center;
      border-bottom: #dcdcdc 1px solid;
      &:last-child{
        border-bottom: 0px;
      }
      li{
        margin: 8px 12px;
      }
    }
  }
  .modal-body{
    tbody{
      tr{
        td{
          &:last-child{
            text-align: center;
          }
        }
      }
    }
  }
  .has-accepted{
    i{
      fill: $color-green;
    }
  }
  .delete-warning{
    color: $color-red;
    cursor: pointer;
  }
  .accept{
    color: $color-primary;
    cursor: pointer;
  }
}

@media screen and (max-width: $screen-size-medium) {
  #warning{
    max-width: 100%;
    .modal-topline{
      h3{
        span{
          white-space: nowrap;
        }
      }
    }
    .modal-body{
      .table{
        tbody{
          tr{
            td{
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}