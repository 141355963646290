#announcement {
  .table {
    tr {
      td {
        max-width: 250px;
        vertical-align: middle;
      }

      &.active {
        background-color: lighten($color-green, 35);
      }

      &.ended {
        background-color: lighten($color-red, 30);
      }
    }
  }
}
