.tag-option-popup {
  max-width: 700px;
  background-color: #FFF;
  width: 100%;
  border-radius: $border-radius;
  padding: 8px;

  select {
    flex: 1 1 calc(50% - 8px);
    width: calc(50% - 16px);
    margin: 8px;
  }

  .add-tag-container {
    margin-bottom: 8px;

    input {
      flex-grow: 1;
    }
  }
}
