@import "src/sass/variables";

.advanced-editor {
  width: 100vw;
  //height: 100vh;
  border-radius: 0;
  overflow: auto;
  background-color: #FFF;
  &.advanced-editor-drawer{
    padding: $v3-padding-drawer;
  }
  .error {
    border: 1px solid $color-red;
  }
  &.modal-container{
    .modal-body{
      display: flex;
      .excerpt-textarea{
        min-height: 150px;
        resize: none;
      }
      .mk-editor{
        margin-top: 16px;
        &.error {
          border: 1px solid $color-red;
        }
      }
      .main{
        flex: 1 1 75%;
        padding-right: 16px;
      }
      .sidebar{
        flex: 1 0 25%;
        padding-left: 16px;
        min-width: 276px;
      }
    }
  }
  .create-article{
    margin-left: 4px;
    color: blue;
    cursor: pointer;
  }
  .inner-block{
    padding-bottom: 16px;
    width: 100%;
    .group-search{
      display: flex;
      .input-search-bar{
        flex: 1;
      }
    }
    .current-selected-article{
      margin-top: 8px;
      font-size: 13px;
      color: $color-text-default;
      .delete-current-article{
        margin-left: 8px;
        color: $color-red;
        cursor: pointer;
      }
    }
    .input-label{
      display: block;
      margin-bottom: 8px;
      color: $color-text-default;
      font-size: 15px;
    }


    .add-tag-container {
      padding: 8px;
      min-height: 50px;
      line-height: 18px;
      border: 1px solid darken($color-grey-light, 10);
      border-radius: $border-radius;
      font-size: 15px;
      max-height: 200px;
      overflow: auto;
      flex-wrap: wrap;
      input {
        background-color: #FFF;
        width: 100%;
        border-bottom: 2px solid $color-primary;
        margin: 4px;
      }
      .max-characters{
        text-align: right;
        display: block;
        width: 100%;
      }
      .tag-list {
        margin: 4px;
      }
    }
    .max-tag{
      width: 100%;
      text-align: right;
      font-size: 14px;
      color: $color-text-default;
      display: block;
      margin-top: 8px;
    }

    .custom-textarea{
      min-height: 250px;
    }
    &:last-child{
      padding-bottom: 0;
    }
    &.img-cover-preview{
      display: flex;
      flex-direction: column;
      label{
        margin-bottom: 8px;
      }
      .preview{
        max-width: 300px;
        max-height: 300px;
        border-radius: $border-radius;
      }
      .cancel-reverse{
        margin-top: 8px;
        max-width: 300px;
      }
    }
  }

  .container {
    margin-top: 16px;
    border-radius: $border-radius;
    padding-top: 16px;

    .card {
      box-shadow: none;
    }
  }

  .text-updated{
    color: red;
    text-align: right;
    padding: 8px;
    padding-right: 20px;
  }

  .forum-form {
    width: 100%;
    padding: 0 8px;
    background-color: #FFF;
    border-radius: $border-radius;
    padding-left: 8px !important;

    .answer-title {
      font-size: 20px;
      font-weight: bold;
      color: $color-text-default;
      margin-bottom: 16px;
    }

    .input-group {
      display: flex;
      flex-direction: column;
      margin: 8px 0;

      label {
        font-size: 16px;
        padding: 8px 0;
        color: $color-text-grey-medium;
      }

      img {
        max-width: 100%;
        max-height: 250px;
        margin: 8px;
      }

      .span-edit {
        font-size: 13px;
        color: $color-text-medium-2;
        margin: 8px 0;
        font-weight: normal;
      }

      .remove-picture {
        display: flex;
        align-self: flex-start;
        font-size: 13px;
        padding: 8px;
        margin-right: 8px;
      }

      &.tag {
        textarea {
          min-height: 50px;
          width: 100%;
        }
      }

      &.row {
        flex-direction: row;
        align-items: center;
        margin-top: 16px;

        label {
          margin-right: 8px;
        }
      }

      &.align-center {
        align-items: center;
        justify-content: center;
      }
    }

    .advanced-editor-btn-group {
      display: flex;
      margin: 16px;
      justify-content: center;
    }

    .btn-group {
      &.tag-report {
        .report {
          margin-left: auto;
        }
      }
    }

    .content-wrapper {
      display: flex;
      flex-wrap: wrap;
      background-color: rgba($color-grey-light, .2);
      margin-bottom: 16px;
      border-radius: $border-radius;

      .topline-user-answer {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        width: 100%;
        font-weight: 400;
        padding: 16px 8px;
        background-color: $color-light-pink;
        border-bottom: 4px solid #de87de;
        border-radius: 5px;

        &.man {
          a {
            color: $color-primary;
          }
        }

        &.woman {
          a {
            color: $color-main;
          }
        }

        &.couple {
          a {
            color: $color-purple;
          }
        }

        &.guest {
          color: $color-grey-medium;
        }

        a {
          text-decoration: none;
          color: $color-primary;
        }
      }

      .user-informations {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 8px;
        width: 20%;
        font-size: 14px;
        font-weight: normal;
        text-align: center;

        div {
          color: $color-grey-darken;
        }

        .role-status, .member-since, .grade {
          margin-bottom: 16px;
        }

        .avatar {
          width: 70px;
          height: 70px;
          margin-bottom: 16px;
        }

        .message-count {
          color: $color-text-grey-medium;
          margin-bottom: 16px;
        }

        .symbols {
          display: inline-block;
          margin: 0;
          margin-bottom: 16px;
        }
      }

      .user-content {
        display: flex;
        flex-direction: column;
        padding: 16px 8px;
        align-items: flex-start;
        width: calc(80% - 16px);
        margin: 0 8px;
        word-break: break-all;

        img {
          max-width: 100%;
        }

        .title {
          padding-bottom: 8px;
          margin-bottom: 8px;
          font-weight: 700;
          font-size: 18px;
          width: 100%;
        }

        .user-text {
          width: 100%;
          padding-bottom: 32px;
          font-size: 16px;
          font-weight: 400;
          color: $color-text-default;
          word-break: break-word;

          a {
            color: $color-primary;
            text-decoration: none;
          }

          .date {
            font-size: 13px;
            color: $color-text-grey-medium;
            margin-bottom: 16px;
          }
        }

        .edited-answer {
          display: flex;
          flex-direction: column;
          color: $color-text-medium-2;
          margin-top: 16px;

          span {
            width: 100%;
            margin: 4px 0;
            font-weight: 700;
            font-size: 14px;

            &:last-child {
              margin-bottom: 8px;
            }

            i {
              maring-right: 4px;
            }

            a {
              color: $color-primary;
              text-decoration: none;
            }
          }
        }

        .user-sign {
          width: 100%;
          border-top: 1px solid $color-grey-light;
          padding: 16px 8px 16px 0;
          word-break: break-all;
          font-family: $font-for-readability;
        }
      }
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: $border-radius;
    //overflow: hidden;
    h4 {
      color: #FFF;
      //background-color: $color-primary-pink;
      background: $v3-color-gradient;
      letter-spacing: .04em;
      border-radius: $border-radius;
      line-height: 18px;
      font-size: 14px;
      padding: 8px;
      margin-bottom: 8px;
    }

    .advanced-editor-block {
      padding-bottom: 16px;
      &:last-child{
        padding-bottom: 0;
      }

      .advanced-editor-content {
        padding: 8px 0;

        .row {
          align-items: center;

          input[type="checkbox"] {
            margin-right: 8px;
          }
        }

        &.categories {
          max-height: 130px;
          overflow: auto;
        }
      }

      label {
        width: 100%;
        font-size: 14px;
      }

      input[type="date"], input[type="time"] {
        width: 100%;
        border: 1px solid $color-grey-light;
        margin: 8px 0;
        padding: 8px;
      }

      &:first-child {
        margin-top: 0;
      }

      .column {
        margin: 16px 0;

        input {
          margin-top: 8px;
        }

        textarea {
          margin-top: 8px;
          resize: vertical;
          min-height: 80px;
        }
      }
    }

    .private-club-container {
      max-height: 250px;
      overflow: auto;

      .friends-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 4px 0;
        padding: 4px 16px;
        border: 1px solid $color-grey-light;
        border-radius: $border-radius;
        background-color: #FFF;
        user-select: none;

        .friend {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0;
          font-size: 14px;
          color: $color-text-default;

          .avatar {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
        }
      }

      span {
        margin-left: auto;
        .icon-close {
          width: 10px;
          fill: $color-red;
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }

    .row {
      margin: 8px 0;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .advanced-editor {
    margin-right: 0px;
    width: 100vw;
    height: 100vh;
    padding-bottom: 16px;
    &.editor-drawer{
      height: auto;
    }
    &.modal-container{
      .modal-body{
        flex-wrap: wrap;
        .main{
          padding-right: 0;
          flex: 1 1 100%;
        }
        .sidebar{
          padding-left: 0;
          flex: 1 1 100%;
          margin-top: 8px;
          padding-top: 8px;
          border-top: 1px solid $color-grey-light;
        }
      }
    }
    .layout-sidebar {
      height: calc(100% - 38px);
      overflow: auto;
    }

    .forum-form {
      order: 2;

      .answer-title {
        margin-top: 16px;
      }

      .content-wrapper {
        .user-informations {
          width: 100%;
        }

        .user-content {
          width: 100%;
          margin: 0;

          .user-buttons {
            .answer-topic-admin-button {
              margin-bottom: 8px;
            }

            margin: 0;
            flex-wrap: wrap;
            justify-content: flex-start;
          }
        }
      }
    }

    .sidebar {
      order: 1;
      margin-left: 0;
    }

    .container {
      margin-top: 0px;
      padding-top: 0px;
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .advanced-editor {
    padding-bottom: 60px;

    .forum-form {
      .btn-group {
        flex-direction: column;
        text-align: center;

        .btn {
          margin: 0 !important;
        }
      }

      .advanced-editor-btn-group {
        flex-direction: column;

        .btn {
          width: 100%;
          margin: 4px 0;
        }
      }
    }
  }
}
