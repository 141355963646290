#photoReview {
  .block {
    flex: 1 1 calc(100% / 2 - 16px);
    margin: 8px;
    padding: 8px;
  }

  .reported-user {
    padding: 8px;

    .user-infos {
      flex-grow: 1;
    }

    .text {
      line-height: 18px;
    }

    .avatar {
      width: 80px;
      height: 80px;
      margin-right: 16px;
    }

    .report-message {
      border: 1px solid $color-grey-light;
      border-radius: $border-radius;
      padding: 16px;
      line-height: 18px;
      font-size: 15px;
      color: $color-text-default;
    }

    .report {
      padding-bottom: 8px;
      border-bottom: 1px solid $color-grey-light;
    }
  }

  .album-container {
    max-height: 400px;
    overflow: auto;

    .img-container {
      margin: 2px;
      width: calc(100% / 5 - 4px);
      max-width: calc(100% / 5 - 4px);
      padding-top: calc(100% / 5 - 4px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
    }
  }

  h5 {
    font-size: 15px;
    color: $color-text-medium-2;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .preset-message {
    font-size: 14px;
    color: $color-primary;
    font-weight: bold;
    line-height: 18px;
    cursor: pointer;
  }
}

@media screen and (max-width: $screen-size-small) {
  #photoReview {
    .reported-user {
      padding: 0;
    }

    .report {
      margin: 16px 0;
    }

    .profil-btn-group, .report-btn-group, .concerned-album-btn-group {
      width: 100%;

      .btn {
        flex-grow: 1;
      }
    }

    .concerned-album {
      .concerned-album-topline {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
