@import 'src/sass/_variables.scss';


.ui-input-wrapper, .ui-input-select-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.ui-input-label{
  font-size: .8em;
  padding: 4px 2px;
  display: inline-flex;
  align-items: center;
  user-select: none;
  letter-spacing: -0.01em;
}

.ui-input-password-strength{
  margin-top: 8px;
  margin-bottom: 4px;
}
textarea.ui-input{
    padding: 12px;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
}
.ui-input, .ui-input-select{
  display: inline-flex;
  border-radius: $v3-default-border-radius;
  border: 1px solid darken($v3-color-bg-light-grey, 2);
  border-top: 1px solid darken($v3-color-bg-light-grey, 5);
  height: 44px;
  color: #000;
  background: lighten($v3-color-bg-light-grey, 0);
  position: relative;
  align-items: center;
  font-size:initial;
  input, textarea, .ui-input-select-content{
    width: 100%;
    border: none;
    padding: 0 12px;
    font-size: .85em;
    height: 100%;
    background: transparent;
    border-radius: $v3-default-border-radius;
    &.has-icon-right{
      padding-right: 44px;
    }
    @include placeholder{
      color: #777777;
    }
  }
  
  .ui-input-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 8px;
    user-select: none;
    cursor: pointer;
    height: 24px;
    width: 24px;
    border-radius: 100px;
    &:hover{
      background: $v3-color-bg-light-grey-hover;
    }
  }
  .ui-input-error{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: .7em;
    color: $color-red;
    padding: 4px 12px;
  }
  &.full{
    width: 100%;
  }
  &.error{
    border-color: $color-red;
    input, .ui-input-select-content{
      padding-bottom: 12px;
    }
  }
}

.ui-input-select{
  .ui-input-select-content{
    color: #777777;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.selected{
      color: #000;
    }
  }
  &:hover{
    user-select: none;
    background: $v3-color-bg-light-grey-hover;
    cursor: pointer;
    border-color: darken($v3-color-bg-light-grey-hover, 5);
    .ui-input-select-content{
      color: #4e4e4e;
    }
  }
}

.ui-input-select-options{
  padding: 4px;
  width: 100%;
  background: $v3-color-bg-light-grey;
  border-radius: $v3-default-border-radius;
  .ui-input-select-option{
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 42px;
    border-bottom: 1px solid $v3-color-bg-light-grey-hover;
    font-size: .85em;
    padding: 0 16px;
    color: #000;
    .ui-input-select-option-icon{
      margin-right: 8px;
    }
    &.selected{
      font-weight: 500;
      // border-radius: $v3-default-border-radius;
      // background: $v3-color-bg-light-grey-hover;
    }
    &:hover{
      background: $v3-color-bg-light-grey-hover;
      cursor: pointer;
    }
    &:last-child{
      border-bottom: none;
    }
  }
}

.ui-autocompletion{
  position: relative;
  &.full{
    width: 100%;
  }
  &-suggestions{
    width: calc(100% - 8px);
    margin-left: 4px;
    // background: $v3-color-bg-light-grey;
    background: #FFF;
    border: 1px solid $v3-color-bg-light-grey-hover;
    border-radius: 8px;
    margin-top: 4px;
    position: absolute;
    z-index: 100;
    box-shadow: $v3-shadow;
  }
  &-suggestion{
    padding: 16px;
    border-bottom: 1px solid $v3-color-bg-light-grey-hover;
    font-size: .85em;
    color: #000;
    &:hover{
      background: $v3-color-bg-light-grey-hover;
      cursor: pointer;
    }
    &:last-child{
      border-bottom: none;
    }
    &.highlighted{
      background: $v3-color-bg-light-grey-hover;
    }
  }
}