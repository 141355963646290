
@import 'reset';
@import 'variables';
@import 'mixins';
@import 'layout';
@import 'spa-update.scss';
//components
@import 'components/_modal-container';
@import 'components/_avatar';
@import 'components/_answers.scss';
@import 'components/_album-informations';
@import 'components/_album-grid';
@import 'components/_alert.scss';
@import 'components/advanced-editor/advanced-editor';
@import 'components/_articles-card-small';
@import 'components/_articles-card';
@import 'components/_buttons';
@import 'components/_cards';
@import 'components/card-controls.scss';
@import 'components/_carousel';
@import 'components/_edit-comment';
@import 'components/_change-picture';
@import 'components/_check-request.scss';
@import 'components/_dropdown';
@import 'components/edit-status.scss';
@import 'components/_form-field';
@import 'components/_forum-card';
@import 'components/forums-card-category.scss';
@import 'components/_footer';
@import 'components/_follows';
@import 'components/_gender';
@import 'components/_header';
@import 'components/_input-post.scss';
@import 'components/_icon.scss';
@import 'components/share-media';
@import 'components/_likes';
@import 'components/_loader';
@import 'components/loading-style';
@import 'components/_media-edit';
@import 'components/editor/_article-style';
@import 'components/editor/chat-editor';
@import 'components/editor/mk-editor';
@import 'components/editor/read-only-editor';
@import 'components/editor/mention-suggestions';
@import 'components/editor/emoji';
@import 'components/editor/add-item';
@import 'components/_modal';
// @import 'components/_navbar';
@import 'components/last-visitors';
//@import 'components/_report';
@import 'components/join-attachement.scss';
@import 'components/ignore-user.scss';
@import 'components/_player';
@import 'components/_progress-bar.scss';
@import 'components/_popup';
@import 'components/_profil-follow';
@import 'components/_register';
@import 'components/_rc-slider.scss';
@import 'components/_move';
@import 'components/_status-card';
@import 'components/subscribe';
@import 'components/survey/survey';
@import 'components/survey/survey-create';
@import 'components/survey/survey-edit';
@import 'components/_tag-list';
@import 'components/_tag-options';
@import 'components/tag-options-popup';
@import 'components/_thumb';
@import 'components/toastify-custom';
@import 'components/_toggle';
@import 'components/_upload-summarize.scss';
@import 'components/user-stat-list.scss';
@import 'components/_view-more';
@import 'components/_views';
@import 'components/_webcam-ads.scss';
@import 'components/_warning-head.scss';
@import 'components/advanced-editor/topic-preview';
@import 'components/advanced-editor/article-preview';
@import 'components/comments/_media-comments.scss';
@import 'components/friends/friends-list';
@import 'components/friends/components/_friends.scss';
@import 'components/friends/components/_avatar-friends.scss';
@import 'components/user-by-ip';

@import 'components/badge';
@import 'components/date-serarator';
@import 'components/global';
@import 'components/status-dot';
@import 'components/table';
@import 'components/sidebar-menu';
@import 'components/filter';
@import 'components/title';
@import 'components/margin';
@import 'components/padding';

@import "components/autocompleted-tags";
@import 'components/uploads/_uploads.scss';
@import 'components/uploads/components/_media-datas';
@import 'components/uploads/components/_uploading-medias';
@import 'components/marketing/mobile-live-tool';
@import 'components/toast';
@import 'components/profileSubNavigation';
@import 'components/profileExpendableDescription';
@import 'components/warnings';
//@import 'components/uploads/components/_first-time';
//@import 'components/uploads/components/_complete';
//pages
@import 'pages/_articles';
@import 'pages/_article';
@import 'pages/_charter.scss';
@import 'pages/_contact.scss';
@import 'pages/_delete-account.scss';
//@import 'pages/disclaimer/_disclaimer.scss';
@import 'pages/_forums';
@import 'pages/facettes/_index.scss';
@import 'pages/_forgot-password';
@import 'pages/_history.scss';

@import 'pages/_privacy-policy.scss';
@import 'pages/_profil';
@import 'pages/_stories';

@import 'pages/_terms.scss';
@import 'pages/_top-users';
@import 'pages/confirm-email.scss';
@import 'pages/comments-moderation/moderation.scss';
@import 'pages/comments-moderation/moderation-popup.scss';
@import 'pages/forums/change-title.scss';
@import 'pages/forums/edit-comment';
@import 'pages/forums/survey.scss';
@import 'pages/forums/warning.scss';
@import 'pages/forums/warn-user.scss';
@import 'pages/forums/warning-detail.scss';
@import 'pages/forums/forum-cards.scss';
@import 'pages/forums/_forums-subcategory';
@import 'pages/forums/_forums-topic';
@import 'pages/forums/_forum-category';
@import 'pages/moderation/content-moderation.scss';
@import 'pages/moderation/components/welcome-moderation.scss';
@import 'pages/moderation/components/member-check.scss';
@import 'pages/moderation/components/blog-check.scss';
@import 'pages/moderation/components/photo-check.scss';
@import 'pages/moderation/components/photo-review.scss';
@import 'pages/moderation/components/member-report.scss';
@import 'pages/moderation/components/search.scss';
@import 'pages/moderation/components/story.scss';
@import 'pages/moderation/components/video.scss';
@import 'pages/moderation/components/photo.scss';
@import 'pages/moderator-pc/alert-center.scss';
@import 'pages/moderator-pc/non-approuved-content.scss';
@import 'pages/moderator-pc/member-management.scss';
@import 'pages/moderator-pc/announcement.scss';
@import 'pages/moderator-pc/deleted-content.scss';
@import 'pages/pc-reporting/list-reportings.scss';
@import 'pages/pc-reporting/reporting';
@import 'pages/profil/profil-informations';

@import 'pages/reset-password';

@import 'pages/settings/settings';
@import 'pages/settings/components/profile-meet-settings';
@import 'pages/tagSearch';
@import 'pages/webcam';
@import 'pages/chat-webcam';

* {
  font-family: $font-primary;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  img {
    display: block;
  }
}

a{
  text-decoration: none;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $main-background-color;

  &.no-scroll {
    overflow: hidden;
  }
}
.error-global {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 26px;
  flex-direction: column;
  .logo{

  }
}

.infinite-scroll-component{
  //overflow: unset !important;
}

.LinesEllipsis{
  @extend .break-long-text;
}

main {
  &.blur {
    img {
      filter: blur(10px);
    }

    .bbc_emoticon {
      filter: unset;
    }

    #disclaimer {
      img {
        filter: unset;
      }
    }

    .navbar-header-wrapper {
      img {
        filter: unset;
      }
    }
  }
  .flex-jc-sb{
    display: flex;
    justify-content: space-between;
  }
  .flex-jc-c{
    display: flex;
    justify-content: center;
  }
  .no-cursor {
    cursor: none;
  }
  .default-cursor {
    cursor: default;
  }
}

.Toastify__toast-container{
  width: initial !important;
}

#view {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - $v3-footer-height - $v3-navbar-height); //16px footer margin top
}

.break-long-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.content-to-read{
  font-family: $font-for-readability;
}

.responsive-active-640 {
  display: none;
}

.blockquote--classic{
  border-left: 3px solid $color-main;
  line-height: 1.4;
  padding: 0 0 0 20px;
  margin: 0;
  font-style: italic;
  background: transparent;
  quotes: none;
  &::before, &::after{
    display: none;
  }
}

.img-preview-container{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  //overflow: hidden;
  .img-preview{
    max-width: 100%;
    max-height: 400px;
  }
}
.w-100 {
  width: 100%;
}
.text-blue{
  color:blue;
}

.text-danger{
  color: $color-red;
}

.uppercase{
  text-transform: uppercase;
}

.staff{
  .avatar{
    width: 50px;
    height: 50px;
  }
}
//.mt--40{
//  margin-top: -15px;
//  .menu-item{
//    padding: 2px 16px !important;
//  }
//}

/* custom scrollbar */
.isLike{
  color:$v3-color-like;
}

* {
  scrollbar-color: darken($v3-color-bg-light-grey, 5%) $v3-color-bg-light-grey-hover;
}
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: darken($v3-color-bg-light-grey, 5%);
  
  border-radius: 20px;
  border: 5px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $v3-color-bg-light-grey-hover;
  cursor: pointer;
}



@media screen and (max-width: $screen-size-medium) {
  body {
    min-height: unset;
  }
  #view {
    min-height: unset;
  }
}

@media screen and (max-width: $screen-size-small) {
  .responsive-hidden-640 {
    display: none !important;
  }
  .responsive-active-640 {
    display: flex;
  }
  .img-preview-container{
    //overflow: hidden;
    .img-preview{
      max-width: 100%;
      max-height: 200px;
    }
  }
}

// .certified-test{
//   display: none;
//   // display: inline-block;
//   width: 14px;
//   border-radius: 10px;
//   height: 14px;
//   background: #e1d36a;
// }
