@import 'src/sass/_variables.scss';
.thumb {
  display: flex;
  flex-direction: column;

  &.medium-size {
    flex: 1;
  }

  .cta-big-plan{
    position: absolute;
    margin: 8px;
    z-index: 9;
    cursor: pointer;
    border-radius: 16px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.40);
    padding: 4px 16px;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i{
      fill: #FFFFFF;
      width: 12px;
    }

    .gros-plan-label {
      margin-left: 4px;
      padding-top: 1px;

      &.lock::before {
        content: 'Flouter contenu sensible';
      }

      &.unlock::before {
        content: 'Déverrouiller contenu sensible';
      }
    }
    
  }

  .thumb-wrapper {
    position: relative;
    display: block;
    cursor: pointer;
    background: #edecec;
    border-radius: $border-radius;
    overflow: hidden;

    .thumb-status {
      position: absolute;
      bottom: 8px;
      font-size: 12px;
      border-radius: 8px;
      left: 8px;
      color: #e3e3e3;
      padding: 4px 8px;
      background: rgba(0, 0, 0, 0.85);
    }


    &.auto-cursor{
      cursor: auto !important;
    }

    &.loading {
      animation: loadingAnimation .6s alternate infinite;
      padding-top: 100%;

      .thumb-link-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &.thumb-video {
        //padding-top: 100%;
      }
    }

    &.loaded {
      /*.thumb-link-wrapper {
        pointer-events: none;
      }*/
      img {
        display: block;
        opacity: 1;
        height: auto;
        transition: opacity .5s ease;
      }
    }

    img {
      display: none;
      opacity: 0;
      width: 100%;
      height: 0px;
      border-radius: $border-radius;
    }

    &.big-plan{
      img{
        -webkit-filter: blur(20px);
        filter: blur(20px);
      }
      img:hover {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        transition: 1s ease !important;
      }
      .imgMobile{
        -webkit-filter: blur(0px);
        filter: blur(0px);
        transition: 1s ease !important;
      }
    }

    .informations-media{
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      //height: 20px;
      /* flex: 1; */
      /* bottom: 0px; */
      width: 100%;
      padding: 4px;
      flex-wrap: wrap;
      pointer-events: none;
    }

    .thumb-topline {
      /*position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 45px;*/
      height: 20px;
      display: flex;
      align-items: center;
      //background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
      border-radius: 12px;
      background-color: rgba(0, 0, 0, 0.6);

      .no-display{
        fill: $v3-color-like;
        color: $v3-color-like;
        font-size: 14px;
        position: initial;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        i {
          fill: $color-redpink;
          width: 12px;
          margin-right: 4px;
        }

      }
      .likes {
        /*position: absolute;
        top: 10px;
        right: 10px;
        background-color: transparent;
        fill: $color-redpink;
        color: $color-redpink;
        font-size: 18px;
        display: flex;
        align-items: center;*/


        fill: $v3-color-like;
        color: $v3-color-like;
        font-size: 12px;
        position: initial;
        padding: 4px 6px;
        i {
          fill: $color-redpink;
          width: 12px;
        }

        &.animate-like {
          i {
            animation: animate-like .25s linear;
            transform: translateZ(0);
            margin-right: 4px;
          }
        }
      }

      .views {
        /*font-size: 18px;
        background-color: transparent;
        color: darken($color-grey-light, 10);
        top: 10px;
        left: 10px;
        display: flex;
        align-items: center;*/

        font-size: 12px;
        background-color: transparent;
        color: #d0d0d0;
        top: 0;
        left: 0;
        display: flex;
        /* align-items: center; */
        position: initial;
        padding: 4px 6px;

        i {
          width: 12px;
          fill: darken($color-grey-light, 10);
          margin-right: 4px;
        }
      }
    }

    .duration {
      /*position: absolute;
      width: 100%;
      top: calc(50% - -27px);
      text-align: center;
      border-radius: 50px;*/
      color: #FFFFFF;
      font-size: 12px;
      text-shadow: 0px 0px 4px #000000;
      border-radius: 12px;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      padding: 4px 6px;
      i {
        width: 10px;
        fill: #FFFFFF;
        fill: darken($color-grey-light, 10);
        margin-right: 4px;
      }
    }

    .btn-play {
      position: absolute;
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: calc(50% - 24px);
      left: calc(50% - 24px);
      //background: $color-grey-light;
      border-radius: 50px;
      color: #e3e3e3;
      background: rgba(0, 0, 0, 0.85);

      i {
        //fill: $color-primary;
        fill: #e3e3e3;
        margin-right: -2px;
        //margin-top: 3px;
        width: 16px;
      }
    }
  }

  .title {
    line-height: 1.3;
    font-weight: bold;
    font-size: 14px;
    flex: 1 1 78%;
    cursor: pointer;
    word-wrap: break-word;
    overflow: hidden;
    text-decoration: none;
    color: $color-text-default;
  }

  .description {
    color: $color-text-grey-medium;
    line-height: 14px;
    font-size: 13px;
    margin-bottom: 10px;
    word-wrap: break-word;
    word-break: break-all;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    //align-items: center;
    //flex-wrap: wrap;
    // height: 60px;
    padding-top: 4px;
    align-content: space-between;
    // margin-bottom: 10px;
    flex: 1;

    .media-author {
      flex: 1;
      display: flex;
      align-items: center;

      img {
      }

      .link-thumb {
        flex: 1;
      }
    }

    .media-time {
      font-size: 12px;
      color: gray;
    }

    .avatar {
      width: 33px;
      height: 33px;
      border-radius: $border-radius-rounded;
      margin-right: 8px;

      &.hidden {
        display: none;
      }
    }

    .infos-thumb-text {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .infos-thumb {
      flex: 1;

      .link-thumb {
        @include overflow-ellipsis();
      }

      &.mini {
        display: flex;
        flex-direction: column;
      }

      .name {
        font-size: 13px;
        color: $color-primary;
        text-decoration: none;
        line-height: 1.3;

        &.hidden {
          display: none;
        }
      }

      .views {
        font-size: 13px;
        color: $color-text-grey-medium;
      }
    }
  }

  .edit-media {
    position: absolute;
    right: 5px;
    bottom: 5px;
    user-select: none;
    background: rgba(0, 0, 0, .9);
    height: 30px;
    width: 30px;
    border-radius: 30px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8em;
    visibility: hidden;
    opacity: 0;
    .icon-svg{
      fill:#d6d6d6;
    }
  }

  &:hover {
    .edit-media {
      opacity: 1;
      visibility: visible;
    }
  }

  &.members-only {
    .thumb-wrapper {
      .message {
        position: absolute;
        display: flex;
        left: 0;
        right: 0;
        top: 0;
        bottom: 20px;
        padding: 10px;          
        align-items: center;
        justify-content: center;
        pointer-events: none;

        &::before {
          content: 'Contenu reservé aux membres de Voissa.\a\aInscrivez-vous pour y accéder.';
          color: #888888;
          font-size: 14px;
          text-align: center;
          white-space: pre-line;
        }
      }
    }
  }

  &.friend-only {
    .thumb-wrapper {
      .message {
        position: absolute;
        display: flex;
        left: 0;
        right: 0;
        top: 0;
        bottom: 20px;
        padding: 10px;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        &::before {
          content: 'Contenu réservé à son cercle.';
          color: #888888;
          font-size: 14px;
          text-align: center;
          white-space: pre-line;
        }
      }
    }
  }
}

@keyframes loadingAnimation {
  from {
    background: #edecec;
  }
  to {
    background: #e0e0e0;
  }
}

@media screen and (max-width: $screen-size-medium) {
  .thumb {
    .big-plan{
      img{
        -webkit-filter: blur(20px);
        filter: blur(20px);
      }
      img:hover {
        -webkit-filter: blur(20px);
        filter: blur(20px);
      }
      .imgMobile{
        -webkit-filter: blur(0px);
        filter: blur(0px);
        transition: 3s ease !important;
      }
    }
    .wrapper {
      .row {
        flex-wrap: nowrap !important;
      }

      .date-since {
        padding: 0 4px;
      }
    }

    .title {
      flex: none;
      flex: 1 1 100%;
    }


  }
}
