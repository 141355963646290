.edit-comment{
  .block{
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    border-bottom: 1px solid $color-grey-light;
    &:last-child{
      border-bottom: 0;
    }
  }
}