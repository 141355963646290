// LAYOUT FLEX

.row {
  display: flex;
  flex-direction: row;
  position: relative;
}

.grow {
  flex-grow: 1;
}

.align-center {
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.no-shrink {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1 1;
}

.flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-arround {
  justify-content: space-around;
}

.align-flex-start {
  align-items: flex-start;
}

.align-flex-end {
  align-items: flex-end !important;
}


.column {
  display: flex;
  flex-direction: column;
}

// LINES ARROUND TEXT
.lines-arround {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-text-default;
  font-size: 14px;
  user-select: none;
  white-space: nowrap;

  &::before {
    content: "";
    height: 1px;
    background-color: $color-grey-light;
    width: 100%;
    margin-right: 16px;
  }

  &::after {
    content: "";
    height: 1px;
    background-color: $color-grey-light;
    width: 100%;
    margin-left: 16px;
  }
}

// TEXT ALIGN
.text-align-center {
  text-align: center;
}

// OVERFLOW
.overflow-hidden {
  overflow: hidden;
}

// TEXT

.see-more {
  display: flex;
  align-items: center;
  color: $color-primary;
  font-size: 12px;
  cursor: pointer;
  justify-self: flex-start;
  align-self: flex-start;
  font-weight: 400;

  .icon-chevron-right {
    fill: $color-primary;
    width: 10px;
    padding-top: 4px;
    margin-left: 8px;
  }
}

.excerpt-style {
  word-wrap: break-word;
  word-break: break-word;
  line-height: 17px;
  font-size: 15px;
  font-family: $font-for-readability;
}

.text {
  color: $color-text-default;
  font-weight: normal !important;

  a {
    text-decoration: none;
    color: $color-primary;
  }

  &.link {
    text-decoration: none;
    color: $color-primary;
  }

  &.small {
    font-size: 12px !important;
  }

  &.medium {
    font-size: 14px !important;
  }

  &.big {
    font-size: 16px;
  }

  &.medium-grey {
    color: $color-text-grey-medium !important;
  }

  &.bold {
    font-weight: bold !important;
  }

  &.pointer {
    cursor: pointer;
  }

  &.good {
    color: $color-green;

    i {
      fill: $color-green;
      width: 10px;
    }
  }

  &.label {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.04em;
    color: lighten($color-text-grey-medium, 10);
  }

  &.pre-line {
    white-space: pre-line;
  }
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

// COLOR

.color-red, .red {
  color: $color-red !important;
}

.color-primary {
  color: $color-primary;
}

.color-main {
  color: $color-main;
}

.color-dark {
  color: $color-text-default !important;
}

// FONT SIZE

.f-size-10 {
  font-size: 10px !important;
}

.f-size-11 {
  font-size: 11px !important;
}

.f-size-12 {
  font-size: 12px !important;
}

.f-size-13 {
  font-size: 13px !important;
}

.f-size-14 {
  font-size: 14px !important;
}

.f-size-15 {
  font-size: 15px !important;
}



// CURSOR

.cursor-pointer {
  cursor: pointer;
}

// SIZE

.full {
  width: 100%;
}

.half {
  width: 50%;
}

// GLOBAL STYLES

.warning {
  color: $color-red;
  font-size: 13px;
  margin: 8px 0;
  padding: 8px;
  background-color: lighten($color-red, 25%);
  border-radius: $border-radius;
}

.text-danger {
  color: $color-red;
  font-size: 13px;
}

.text-success {
  color: $color-green;
  font-size: 13px;
}

.infos-span{
  color: $color-primary;
  font-size: 13px;
  margin: 8px 0;
  padding: 8px;
  background-color: lighten($color-primary, 38%);
  border-radius: $border-radius;
  a{
    color: $color-primary;
    font-weight: 600;
  }
}

.max-characters {
  font-size: 12px;
  color: $color-text-medium-2;
  display: flex;
  font-weight: normal;

  &.max-reached {
    color: $color-red;
  }
}

.read-more {
  color: $color-primary;
  font-size: 12px;
  width: 100%;
  display: block;
  margin: 4px 0 8px 0;
  cursor: pointer;
  user-select: none;
}

// OTHERS

.no-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  text-align: center;
  width: 100%;
  color: lighten($color-text-medium-2, 30);
  padding: 32px 8px;
  letter-spacing: .02em;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius;
  user-select: none;
  background-color: #FFF;
  transition: all .2s linear;

  &.small {
    font-size: 14px;
  }

  &.big {
    font-size: 20px;
  }

  &.no-border {
    border: 0px;
  }

  &.small-padding {
    padding: 8px 0;
  }

  &.text-align-left {
    text-align: left;
    justify-content: flex-start;
  }
}

.update-done {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: $color-text-default;
  justify-content: center;
  align-items: center;
  animation: updateInfos 1s normal forwards ease-out;
  z-index: 4;
  background-color: rgba($color-text-default, .6);
  font-weight: 700;

  .valide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    color: $color-text-default;
    font-size: 18px;
    background-color: #FFF;
    border: 2px solid $color-grey-light;
    border-radius: $border-radius;

    i {
      fill: darken($color-green, 5%);
      width: 18px;
      margin-left: 16px;
    }
  }

  .warning {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    color: $color-text-default;
    font-size: 18px;
    background-color: #FFF;
    border: 2px solid $color-grey-light;
    border-radius: $border-radius;

    i {
      fill: $color-red;
      width: 18px;
      margin-left: 16px;
    }
  }
}

#signin-ads-wrapper {
  padding: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .signin {
    color: #FFF;
    flex: 1;

    h2 {
      font-weight: bold;
      font-size: 37px;
    }

    h3 {
      margin: 24px 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.3em;
    }
  }

  .ads {
    background: #cbcbcb;
    height: 90px;
    width: 728px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../images/images-test/175x175/10.jpeg');
    background-size: cover;
    color: #FFF;
    font-weight: bold;
  }
}

.klingon-banner {
  width: 100%;
  max-height: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../images/images-test/175x175/10.jpeg');
  background-size: cover;
  color: #FFF;
  font-weight: bold;
}

//SEARCHBAR

.input-search-bar{
  position: relative;
  .custom-text-input{
    justify-content: center;
  }
  .icon-chevron-down{
    transition: all .1s linear;
    position: absolute;
    right: 16px;
    pointer-events: none;
  }
  .sub-search-bar{
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 1;
    max-height: 300px;
    overflow: auto;
    border-radius: 0 0 $border-radius $border-radius;
    background-color: #FFF;
    border: 1px solid darken($color-grey-light, 10);
    border-top: 0px;
    box-shadow: $box-shadow-light;
    .show-loading-bar{
      display: block;
    }
    .hide-loading-bar{
      display: none;
    }
    .sub-search-bar__item{
      padding: 12px;
      border-top: 1px solid $color-grey-light;
      font-weight: 600;
      color: $color-text-default;
      font-size: 14px;
      cursor: pointer;
      &:hover{
        background-color: $color-grey-light;
      }
      &.no-result{
        color: lighten($color-text-grey-medium, 15);
        cursor: default;
        &:hover{
          background: transparent;
        }
      }
      &:first-child{
        border-top: 0;
      }
    }
  }
  &.active{
    .icon-chevron-down {
      transform: rotate(180deg);
    }
    .custom-text-input{
      input{
        border-radius: $border-radius $border-radius 0 0;
      }
    }
    .sub-search-bar{
      display: block;
    }
  }
}

// ANIMATIONS

@keyframes updateInfos {
  0% {
    opacity: 0;
    visibility: visible;
  }
  2% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes animate-like {
  50% {
    transform: scale(1.4);
  }
}

@media screen and (max-width: $screen-size-medium) {
  body {
    // input, textarea {
    //   font-size: 16px !important;
    // }

    #main, main {
      .update-done {
        .valide {
          height: 100%;
        }

        .warning {
          height: 100%;
        }
      }

      &.bgHeart {
        background: none;
      }
    }
  }
  .klingon-banner {
    max-height: 200px;
    height: 200px;
  }
}


@media screen and (max-width: $screen-size-small) {
  iframe {
    width: 100%;
    height: auto;
  }
}
