.article-preview{
  max-width: 1200px;
  width: 100vw;
  .modal-body{
    .wrapper-text-article {
      max-width: 740px;
      margin: auto;
      line-height: 1.8;
      @extend .break-long-text;
      flex: 1;
      color: $color-text-default;
      font-size: 16px;
      white-space: pre-wrap;
      font-family: $font-for-readability;
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        margin-top: 8px;
        margin-bottom: 8px;
        color: $color-text-default;
      }

      img, iframe, a {
        max-width: 100%;
        border-radius: 16px;
        overflow: hidden;
      }

      a {
        @extend .break-long-text;
      }

      .article-paragraph {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid $color-grey-light;

        h3 {
          font-size: 20px;
          margin: 12px 0;

          &:first-child {
            margin-top: 0;
          }
        }

        p {
          line-height: 1.2;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}