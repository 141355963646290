@import '../../../sass/variables';

#email-unconfirmed{
  padding: 16px;
  .logo{
    img{
      height: 25px;
    }
  }
  .email-unconfirmed-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .email-unconfirmed-title{
    font-size: 1.4em;
    line-height: 1.3em;
    letter-spacing: -0.01em;
    max-width: 300px;
    font-weight: 500;
    margin-bottom: 16px;
    color: #000;
    margin-bottom: 8px;
    word-break: break-all;
  }
  .email-unconfirmed-infos{
    line-height: 1.4em;
    font-size: 0.8em;
    margin-bottom: 8px;
    .email-unconfirmed-infos-email{
      font-weight: bold;
    }
  }
  .email-unconfirmed-form{
    margin-top: 24px;
    .email-unconfirmed-form-email-not-received-error{
      color: red;
      padding-bottom: 8px;
    }
    .email-unconfirmed-form-email-success{
      color: $color-green;
      text-align: center;
      margin-top: 16px;
    }
    .email-unconfirmed-form-label{
      font-weight: bold;
      margin-bottom: 4px;
    }
    .email-unconfirmed-form-email-received{
      display: flex;
      align-items: center;


    }
  }
}