@import "src/sass/variables";

.member-card{
    position: relative;
    background: #000;
    border-radius: 16px;
    overflow: hidden;
    font-size: .9em;
    cursor: pointer;
    padding-top: 150%;
    img{
        position: absolute;
        top: 0;
        opacity: .9;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-wrapper{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1;
        flex-direction: column; 
        justify-content: flex-end;           
        
    }
    &-header{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 100%);
        padding: 8px;
        padding-bottom: 0;
        color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .username{
            font-size: 1.2em;
            margin-bottom: 12px;
            max-width: 100%;
            white-space: nowrap; 
            overflow: hidden;  
            text-overflow: ellipsis; 
            font-weight: 600;
            text-transform: capitalize;
        }
    }
    &-info{
        display: flex;
        color: #FFF;
        // flex-shrink: 0;
        flex-wrap: wrap;
        padding: 8px;
        padding-bottom: 2px;
        background: rgba(0, 0, 0, 0.55);
        &-tag{
            display: flex;
            min-height: 24px;
            align-items: center;
            flex-shrink: 0;
            background: rgba(120, 120, 120, 0.5);
            // background: #FFF;
            text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
            border-radius: 50px;
            // color: #000;
            font-size: .7em;
            font-weight: 600;
            padding: 0 8px;
            margin-right: 6px;
            margin-bottom: 6px;
        }
    }

}