.user-follow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  border-radius: $border-radius;
  border: 1px solid $color-grey-light;

  .avatar-link {
    margin-right: 16px;

    .avatar {
      width: 60px;
      height: 60px;
    }
  }

  .column {
    flex-grow: 1;
    height: 100%;

    .username {
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      color: $color-primary;
    }

    .description {
      margin: 12px 0;
      font-size: 13px;
      line-height: 1.2;
      height: 46px;
      max-height: 46px;
      overflow: hidden;
      font-family: $font-for-readability;
      @extend .break-long-text;
      .no-description {
        color: $color-text-medium-2;
      }
    }

    .follow-profile-button {
      margin-top: auto;
    }
  }
}
