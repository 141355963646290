.moderate-popup {
  background-color: #FFF;
  border-radius: $border-radius;
  margin: 16px auto;
  overflow: hidden;

  .container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-height: 95vh;
    height: 100%;
    overflow-y: auto;

    .title {
      width: 100%;
      font-weight: 400;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: .04em;
      text-align: center;
      margin: 8px auto;
      color: $color-text-default;
      max-width: 700px;
      padding: 8px;
      flex-shrink: 0;
      word-break: break-word;
      word-wrap: break-word;
    }

    .row {
      flex-shrink: 0;
    }

    .img-container {
      margin: 16px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 300px;
      max-width: 100%;
      overflow: hidden;
      flex-shrink: 0;

      .article {
        width: 100%;
        overflow: auto;
        max-width: 700px;
        max-height: 300px;
        margin: 0 8px;
        word-break: break-word;
        word-wrap: break-word;
        font-size: 15px;
        line-height: 18px;
        opacity: .8;
        padding: 16px;
        border-radius: $border-radius;
      }

      img {
        max-width: 100%;
        max-height: 300px;
      }
    }

    .btn {
      margin-right: 8px;
    }

    .comments-to-moderate {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding-top: 8px;
      border-top: 1px solid $color-grey-light;

        .comments-container{
          margin-top: 8px;
          margin-bottom: 8px;
          max-width: 700px;
          width: 100%;
            .comments{
              background-color: transparent;
            .comments-list{
              border-top: 0;
            }
          }
        }

      .comment {
        background-color: #FFF;
        margin: 8px 0;
        width: 100%;
        max-width: 700px;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $color-grey-light;
        padding: 8px 8px 16px 8px;
        margin: 8px 0 0 0;
        flex-shrink: 0;
        border-radius: $border-radius;
        transition: all ease-in-out .3s;

        .author {
          align-items: center;
          padding: 8px;

          .avatar {
            width: 40px;
            height: 40px;
            margin-right: 8px;
          }

          .author-infos {
            font-size: 14px;
            color: $color-text-default;

            a {
              text-decoration: none;
              color: $color-primary;
            }
          }
        }

        .content {
          display: flex;
          padding: 8px;
          font-size: 16px;
          color: $color-text-medium-2;
          line-height: 1.3;
          opacity: .8;
          font-family: $font-for-readability;
          @include overflow-ellipsis();
        }

        i {
          margin: 0 8px;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
          }

          &.icon-close {
            fill: $color-red;
          }

          &.icon-check {
            fill: $color-green;
          }
        }

        .no-comment {
          font-size: 18px;
          color: lighten($color-text-medium-2, 20%);
          padding: 16px;
          user-select: none;
        }

        &.fade {
          opacity: 0;
          user-select: none;

          i {
            display: none;
          }
        }
        &:first-child{
          margin-top: 0;
        }
      }

      .pagination {
        width: 100%;
        max-width: 550px;
        margin-top: 16px;
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .moderate-popup {
    .container {
      .comments-to-moderate {
        padding: 0 8px;
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .moderate-popup {
    .container {
      margin: 0;

      .comments-to-moderate {
        .comment {
          .author {
            .author-infos {
              flex-direction: column;
              align-items: flex-start !important;

              span {
                margin: 2px 0;
              }
            }
          }
        }
      }
    }
  }
}
