#beta-access{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    .beta-access-wrapper{
        display: flex;
        flex-direction: column;
        max-width: 440px;
        width: 100%;
        padding: 8px;
        img{
            width: 100%;
            max-width: 140px;
        }
        h2{
            font-size: 1.4rem;
            line-height: 1.2em;
            font-weight: 600;
            margin: 0;
            margin-top: 24px;
        }
        p{
            font-size: 1rem;
            font-weight: 400;
            margin-top: 16px;
            line-height: 1.2em;
        }
        form{
            display: flex;
            align-items: center;
        }
        input{
            width: 140px;
            display: flex;
            text-align: center;
            margin-top: 16px;
            padding: 12px 16px;
            border: 1px solid #000;
            border-radius: 0px;
            height: 42px;
            font-weight: 400;
            color: #000;
            font-size: 1.2em;
            background: #f3f3f3;
            &:focus{
                outline: none;
                border-color: #000;
            }
        }
        .error{
            color: #FF0000;
            font-size: .85rem;
            margin-top: 8px;
        }
        .cta{            
            margin-top: 16px;
            display: inline-block;
            text-decoration: none;
            background: #000;
            color: #FFF;
            padding: 12px 16px;
            font-size: 1.1rem;
            height: 42px;
            font-weight: 400;
            user-select: none;
            cursor: pointer;
        }
    }
}