.follows {
  display: flex;
  align-items: center;
}

.btn-follows {
  border-radius: $border-radius;
  padding: 6px 18px;
  margin: 0 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border: 1px solid $color-primary;
  background-color: #FFF;
  color: $color-primary;
  user-select: none;
  font-size: 12px;

  i {
    margin-right: 6px;
    fill: $color-primary;
  }

  &.active {
    border: 1px solid $color-primary;
    background-color: $color-primary;
    color: $color-grey-light;

    i {
      fill: $color-grey-light;
    }
  }
}

.follows-btn {
  border: 1px solid $color-primary;
  background-color: #FFF;
  margin-right: 8px;
  cursor: pointer;
  user-select: none;
  color: $color-primary;

  i {
    margin-right: 4px;
    fill: $color-primary;
    width: 10px;
  }

  &.active {
    background-color: $color-primary;
    color: $color-grey-light;

    i {
      fill: $color-grey-light;
    }
  }
}

.follow-forum, .follow-blog {
  color: $color-grey-medium;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon-caret-up {
    fill: $color-main !important;
  }

  .dropdown {
    .dropdown-children {
      span {
        display: flex;
        background-color: #FFF;
        padding: 5px 8px;
        border-radius: $border-radius;
        box-shadow: $box-shadow-1;

        .icon-group {
          margin-right: 4px;
        }
      }
    }

    .dropdown-menu {
      &.show-follower {
        width: 280px;
        max-height: 300px;
        position: relative;
      }
    }

    .title-item {
      display: flex;
      align-items: center;
      font-size: 18px;
      background-color: $color-main;
      color: #fff;
      padding: 8px 16px;
    }

    .overflow-container {
      overflow: auto;
      height: 240px;

      .menu-item {
        a {
          display: flex;
          width: 100%;

          div {
            font-size: 14px;

            .avatar {
              width: 35px;
              height: 35px;
              margin-right: 16px;
            }
          }

          .user-infos {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .grade {
              font-weight: 400;
            }

            div {
              width: 100%;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.profil-follow {
  border: 1px solid $color-primary;
  color: $color-primary;

  &.active {
    background-color: $color-primary;
    color: #FFF;
  }
}

.followers-count, .following-count {
  border-radius: $border-radius;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  padding: 0px 16px;
  color: $color-primary;
  cursor: default;

  .followers-number {
    font-size: 20px;
    letter-spacing: .04em;
    font-weight: 700;
  }

  .followers-text {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .04em;
    line-height: 18px;
    font-weight: 400;
  }
}


@media screen and (max-width: $screen-size-small) {
  .follow-forum, .follow-blog {
    justify-content: center;

    .dropdown {
      .overflow-container {
        height: 100%;
        max-height: calc(100% - 40px);
      }

      .dropdown-relatif {
        .dropdown-menu {
          width: 100%;
          height: 100%;
          max-height: calc(100% - 40px);
        }
      }
    }
  }
}
